import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Currency } from "../../models/reference_lists/Currency";
import api from "../../services/api";
import { useMutationBase } from "../useMutationBase";
import { useNotification } from "../../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { CONST_CURRENCIES } from "../../utilities/constants/query-contants";

export const getCurrency = (id?: string): Promise<Currency> => {
  return api.get({
    path: `${CONST_CURRENCIES.plural}/${id}`,
  });
};

export const useQueryCurrency = (id?: string) => {
  return useQuery<Currency>([CONST_CURRENCIES.singular, { id }], () => getCurrency(id), {
    enabled: !!id,
  });
};

export const postCurrency = (payload: Currency): Promise<void> => {
  return api.post({ path: CONST_CURRENCIES.plural, body: { ...payload } });
};

export const useSaveCurrency = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Currency) => postCurrency(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_CURRENCIES.singular]);
      queryClient.invalidateQueries([CONST_CURRENCIES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:currency").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteCurrency = (id: string) => {
  return api.delete({ path: `${CONST_CURRENCIES.plural}/${id}` });
};

export const useDeleteCurrency = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteCurrency(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_CURRENCIES.singular]);
      queryClient.invalidateQueries([CONST_CURRENCIES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:currency").toLocaleLowerCase(),
        }),
      );
    },
  });
};
