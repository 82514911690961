import { SecurityFeature } from "../../models/templates/SecurityFeature";
import { Group } from "../../models/templates/Group";
import { TreeNodeNormal } from "antd/lib/tree/Tree";
import { getLocalizedValue } from "../MultilingualHelper";
import { SecurityFeatureSelection } from "../../models/data/SecurityFeatureSelection";
import { Point } from "react-lasso-select/lib/helpers";

export const CreateGroupTreeviewNodes = (
  groups: Group[],
  securityFeatures: SecurityFeature[],
): TreeNodeNormal[] => {
  return groups.map((group) => {
    const securityFeatureNodes = securityFeatures
      .filter((securityFeature) => securityFeature.groupId === group.id)
      .map((securityFeature) => {
        return {
          key: securityFeature.id,
          title: getLocalizedValue(securityFeature.caption),
          selectable: true,
          isLeaf: true,
          children: undefined,
        };
      }) as TreeNodeNormal[];

    return {
      key: group.id as string,
      title: group.name,
      selectable: false,
      isLeaf: false,
      children: CreateGroupTreeviewNodes(group.children as Group[], securityFeatures).concat(
        securityFeatureNodes,
      ),
    };
  });
};

export const constructSecurityFeatureSelection = (selection: SecurityFeatureSelection) => {
  const compiledSecuritySelection = {
    ...selection,
  } as SecurityFeatureSelection;

  switch (compiledSecuritySelection.shapeType) {
    case "RECTANGLE":
      const rectangle = {
        height: selection.height,
        width: selection.width,
        x: selection.x,
        y: selection.y,
      } as Cropper.Data;

      compiledSecuritySelection.height = rectangle.height;
      compiledSecuritySelection.width = rectangle.width;
      compiledSecuritySelection.y = rectangle.y;
      compiledSecuritySelection.x = rectangle.x;
      break;
    case "POLYGON":
      const polygon = selection.points as Point[];
      compiledSecuritySelection.height = undefined;
      compiledSecuritySelection.width = undefined;
      compiledSecuritySelection.y = undefined;
      compiledSecuritySelection.x = undefined;
      compiledSecuritySelection.points = polygon
      break;
    default:
      break;
  }

  return compiledSecuritySelection
}