import { MultilingualInputData } from "../MultilingualInputData";
import { AttributeLink } from "./AttributeLink";
import { ImageTemplate } from "./ImageTemplate";

export class ViewTemplate {
  public id?: string;
  public documentTemplateId?: string;
  public name?: string;
  public caption: MultilingualInputData = {};
  public isRequired: boolean = true;
  public order?: number;
  public imageTemplates: ImageTemplate[] = [];
  public attributeLinks: AttributeLink[] = [];

  public withDocumentTemplate(documentTemplateId: string) {
    this.documentTemplateId = documentTemplateId;
    return this;
  }
}
