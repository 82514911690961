import { useTranslation } from "react-i18next";
import { useState } from "react";
import Search from "antd/lib/input/Search";
import { useQueryDocumentSeriesIndex } from "../../queries/document-series/lists";
import { CmsTable } from "../common/PageComponents";
import { toDateTimeFormat } from "../../utilities/helpers/date-helper";
import dayjs from "dayjs";

export const ChangeTracker = () => {
  const { t } = useTranslation();
  const [queryString, setQueryString] = useState<string | undefined>();
  const [pagination, setPagination] = useState<{ limit: number; skip: number }>({
    limit: 10,
    skip: 0,
  });

  const twoMonthsAgo = new Date(dayjs().add(-2, "month").format("YYYY-MM-DD"));

  const {
    data: search,
    isLoading: isLoadingSearch,
    refetch,
  } = useQueryDocumentSeriesIndex({
    code: queryString,
    orderBy: "modified",
    sortDirection: 2,
    limit: queryString ? 10 : pagination.limit,
    skip: queryString ? 0 : pagination.skip,
    modifiedAfter: twoMonthsAgo,
  });

  return (
    <>
      <Search
        placeholder={t("common:searchQuery")}
        onSearch={async (query) => {
          setQueryString(query);
          await refetch();
        }}
        allowClear
        enterButton
        loading={isLoadingSearch}
      />
      <CmsTable
        loading={isLoadingSearch}
        dataSource={search?.data ?? []}
        rowKey="id"
        columns={[
          {
            title: t("properties:code"),
            dataIndex: "code",
          },
          {
            title: t("properties:assertedBy"),
            dataIndex: "assertedBy",
            render: (value) => value ?? "unkown",
          },
          {
            title: t("properties:modified"),
            dataIndex: "modified",
            render: (value) => toDateTimeFormat(value, "YYYY-MM-DD hh:mm:ss"),
          },
        ]}
        onChange={async (page) => {
          const pageSize = page.pageSize ?? 10;
          const skip = page.current ?? 0;
          setPagination({ limit: pageSize, skip: skip === 1 ? 0 : (skip - 1) * pageSize });
        }}
        pagination={{
          total: search?.count,
          pageSize: pagination.limit,
          pageSizeOptions: ["10", "20", "50"],
        }}
      />
    </>
  );
};
