import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import Config from "../../Config";
import { Role } from "../../models/Role";
import { useCmsContext } from "./CmsContext";

export default function AuthenticatedContext() {
  const { keycloak } = useKeycloak();
  const context = useCmsContext();

  useEffect(() => {
    if (Config.authenticationEnabled) {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        const role = Object.values(Role).find((providerName) => {
          if (
            keycloak.clientId !== undefined &&
            keycloak.resourceAccess !== undefined &&
            keycloak.resourceAccess[keycloak.clientId] !== undefined
          ) {
            return keycloak.resourceAccess[keycloak.clientId].roles.find(
              (roleName) => roleName === providerName,
            );
          } else {
            return keycloak.realmAccess?.roles.find((roleName) => roleName === providerName);
          }
        });

        if (role === undefined) {
          keycloak.logout();
          return;
        }

        context?.setCurrentUser({
          name: keycloak.idTokenParsed?.name,
          role,
        });
      }
    }
  }, [keycloak]);

  return <></>;
}
