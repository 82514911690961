import { MultilingualInputData } from "../MultilingualInputData";

export class AttributeLink {
  public id?: string;
  public attributeId?: string;
  public attributeName?: string;
  public defaultValue?: string;
  public isRequired?: boolean;
  public order?: number;
  public exportable: boolean = true;
  public filterable?: boolean;
  public caption: MultilingualInputData = {};

  public withAttribute(attributeId: string) {
    this.attributeId = attributeId;
    return this;
  }
}
