export default class Config {
  private static json: any;

  public static localStorageLanguageKey: string = "cms_language";
  public static defaultLanguage: string = "en";

  public static get apiUrl(): string {
    return this.json.API_URL;
  }

  public static get appId(): string {
    return this.json.APP_ID;
  }

  public static get realm(): string {
    return this.json.REALM;
  }

  public static get authenticationEnabled(): boolean {
    return !this.json.AUTHENTICATION_ENABLED || this.json.AUTHENTICATION_ENABLED === "true";
  }

  public static get baseUrl(): string {
    return this.json.BASE_URL;
  }

  public static get idsUrl(): string {
    return this.json.IDS_URL;
  }

  public static get dcBaseUrl(): string {
    return this.json.DC_BASE_URL;
  }

  public static get language(): string {
    return localStorage.getItem(this.localStorageLanguageKey) || this.defaultLanguage;
  }

  public static loadConfiguration(): Promise<void> {
    return new Promise((resolve) => {
      fetch(`/config.json?${new Date().getTime()}`).then((response) => {
        response.json().then((json) => {
          this.json = json;
          resolve();
        });
      });
    });
  }
}
