import { useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { CONST_VIDEOS } from "../../utilities/constants/query-contants";
import { Video } from "../../models/data/Video";

export const getVideo = (id?: string): Promise<Video> => {
  return api.get({
    path: `${CONST_VIDEOS.plural}/${id}`,
  });
};

export const useQueryVideo = (id?: string) => {
  return useQuery<Video>([CONST_VIDEOS.singular, { id }], () => getVideo(id), {
    enabled: !!id,
  });
};

export const postVideo = (payload: Video): Promise<Video> => {
  return api.post({ path: CONST_VIDEOS.plural, body: { ...payload } });
};

export const useSaveVideo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Video) => postVideo(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_VIDEOS.singular]);
      queryClient.invalidateQueries([CONST_VIDEOS.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:video").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteVideo = (id: string) => {
  return api.delete({ path: `${CONST_VIDEOS.plural}/${id}` });
};

export const useDeleteVideo = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteVideo(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_VIDEOS.singular]);
      queryClient.invalidateQueries([CONST_VIDEOS.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:video").toLocaleLowerCase(),
        }),
      );
    },
  });
};
