import { Checkbox, Col, DatePicker, InputNumber, Row, Select } from "antd";
import { FormikErrors, useFormikContext } from "formik";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries, DocumentStatus } from "../../../models/data/Document";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { hasSameValues } from "../../../utilities/SetHelper";
import {
  CmsColLayout,
  CmsForm,
  CmsFormItem,
  CmsFormLayout,
  CmsSelect,
  CmsText,
} from "../../common/FormComponents";
import { CmsPageLoader } from "../../common/PageComponents";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import { useCmsContext } from "../../../context/app/CmsContext";
import { updateFormStatusWithSection } from "../../../utilities/helpers/document-helper";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";
import { useQueryMaterialTypes } from "../../../queries/material-types/lists";

export const DocumentDetails = (props: { disabled?: boolean }) => {
  const { canUpdate } = useAuthorization("document");
  const context = useCmsContext();
  const { t } = useTranslation();
  const { values, status, errors, handleChange, setFieldValue, setStatus } =
    useFormikContext<DocumentSeries>();

  const [state, setState] = useState<{
    errors: FormikErrors<DocumentSeries>;
  }>({
    errors: errors ?? {},
  });

  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: materialTypes, isLoading: isLoadingMaterialTypes } = useQueryMaterialTypes();

  const isLoadingAny = isLoadingDocumentTypes || isLoadingMaterialTypes;
  const noDataAny = !documentTypes || !materialTypes;

  useEffect(() => {
    const newStatus = updateFormStatusWithSection(
      status,
      "documentDetails",
      Object.keys(state.errors).length === 0,
    );
    if (!hasSameValues(newStatus.invalidSections, status.invalidSections)) {
      setStatus(newStatus);
    }
  }, [state.errors, status, setStatus]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, errors: errors }));
  }, [errors]);

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <Row>
      <Col {...CmsColLayout}>
        <CmsForm {...CmsFormLayout.twocolumn}>
          <CmsText
            id="code"
            label={t("properties:code")}
            placeholder={t("properties:code")}
            maxLength={50}
            disabled={props.disabled || context?.saveEditMode}
            readOnly={context?.saveEditMode}
            error={state.errors.code}
            value={values.code}
            onChange={handleChange}
          />

          <CmsSelect
            id="documentTypeId"
            label={t("properties:groupType")}
            placeholder={t("properties:groupType")}
            disabled={props.disabled || context?.saveEditMode}
            readOnly={context?.saveEditMode}
            value={values.documentTypeId}
            onChange={(documentTypeId) => setFieldValue("documentTypeId", documentTypeId)}
          >
            {(documentTypes as DocumentType[]).map((item, index) => (
              <Select.Option key={`${index}-${item.id}`} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </CmsSelect>

          <CmsSelect
            readOnly={!canUpdate}
            id="documentStatus"
            label={t("properties:status")}
            placeholder={t("properties:status")}
            disabled={props.disabled}
            value={values.status}
            onChange={(status) => setFieldValue("status", status)}
          >
            {Object.values(DocumentStatus).map((item) => (
              <Select.Option value={item}>{t(`properties:documentStatus${item}`)}</Select.Option>
            ))}
          </CmsSelect>

          <CmsSelect
            readOnly={!canUpdate}
            id="materialType"
            label={t("properties:materialType")}
            placeholder={t("properties:materialType")}
            disabled={props.disabled}
            value={values.materialTypeId}
            onChange={(materialTypeId) => setFieldValue("materialTypeId", materialTypeId)}
          >
            {_.map(materialTypes, (item, index) => (
              <Select.Option key={index} value={item.id}>
                {getLocalizedValue(item.caption)}
              </Select.Option>
            ))}
          </CmsSelect>

          <CmsFormItem label={t("properties:widthMM")}>
            <InputNumber
              readOnly={!canUpdate}
              placeholder={t("properties:width")}
              value={values.width}
              onChange={(e) => setFieldValue("width", e)}
            />
          </CmsFormItem>

          <CmsFormItem label={t("properties:heightMM")}>
            <InputNumber
              readOnly={!canUpdate}
              placeholder={t("properties:height")}
              value={values.height}
              onChange={(e) => setFieldValue("height", e)}
            />
          </CmsFormItem>

          <CmsFormItem label={t("properties:exportable")}>
            <Checkbox
              disabled={!canUpdate}
              checked={values.exportable}
              onChange={(e) => setFieldValue("exportable", e.target.checked)}
            />
          </CmsFormItem>

          <CmsFormItem label={t("properties:inVault")}>
            <Checkbox
              disabled={!canUpdate}
              checked={values.instances?.[0].isInVault}
              onChange={(e) => setFieldValue("instances[0].isInVault", e.target.checked)}
            />
          </CmsFormItem>

          <CmsFormItem label={t("properties:isLent")}>
            <Checkbox
              disabled={!canUpdate}
              checked={values.instances?.[0].isLent}
              onChange={(e) => setFieldValue("instances[0].isLent", e.target.checked)}
            />
          </CmsFormItem>
        </CmsForm>
      </Col>
      <Col {...CmsColLayout}>
        <CmsForm {...CmsFormLayout.twocolumn}>
          <CmsText
            readOnly={!canUpdate}
            id="instances[0].referenceName"
            label={t("properties:referenceName")}
            placeholder={t("properties:referenceName")}
            maxLength={50}
            disabled={props.disabled}
            value={values.instances?.[0].referenceName}
            onChange={handleChange}
          />

          <CmsFormItem label={t("properties:acquisitionDate")}>
            <DatePicker
              disabled={!canUpdate}
              value={
                values.instances?.[0].acquisitionDate
                  ? dayjs(values.instances[0].acquisitionDate)
                  : null
              }
              placeholder={t("properties:date")}
              onChange={(date, datestring) =>
                setFieldValue("instances[0].acquisitionDate", datestring)
              }
            />
          </CmsFormItem>

          <CmsText
            readOnly={!canUpdate}
            id="instances[0].acquisitionNotes"
            label={t("properties:acquisitionNotes")}
            placeholder={t("properties:acquisitionNotes")}
            multiLine={true}
            disabled={props.disabled}
            value={values.instances?.[0].acquisitionNotes}
            onChange={handleChange}
          />

          <CmsText
            readOnly={!canUpdate}
            id="instances[0].acquisitionSource"
            label={t("properties:acquisitionSource")}
            placeholder={t("properties:acquisitionSource")}
            maxLength={50}
            disabled={props.disabled}
            value={values.instances?.[0].acquisitionSource}
            onChange={handleChange}
          />

          <CmsText
            readOnly={!canUpdate}
            id="instances[0].lendingNotes"
            label={t("properties:lendingNotes")}
            placeholder={t("properties:lendingNotes")}
            multiLine={true}
            disabled={props.disabled}
            value={values.instances?.[0].lendingNotes}
            onChange={handleChange}
          />
        </CmsForm>
      </Col>
    </Row>
  );
};
