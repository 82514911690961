import { Button, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Video } from "../../../models/data/Video";
import { CmsUploadButton } from "../../common/ButtonComponents";
import { DeleteOutlined } from "@ant-design/icons";
import update from "immutability-helper";
import { RcFile } from "antd/lib/upload/interface";

export default function VideoManagement(props: {
  videos: Array<Video>;
  onAdd: (file: string | Blob | RcFile, index: number) => void;
  onRemove: (id: string, videos: Array<Video>) => void;
}) {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();

  const columns = [
    {
      title: t("properties:caption"),
      dataIndex: "caption",
      render: (text, video, index) => video.filename,
    },
    canUpdate && {
      title: "",
      width: 10,
      align: "right",
      render: (text: any, video: any, index: number) => (
        <Popconfirm
          title={t("common:confirmDelete")}
          okText={t("common:yes")}
          cancelText={t("common:no")}
          onConfirm={() => {
            const videos = update(props.videos, {
              $splice: [[index, 1]],
            });
            props.onRemove(video.id, videos);
          }}
        >
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            shape="circle"
            size="small"
            style={{ marginTop: "3px" }}
          />
        </Popconfirm>
      ),
    },
  ].filter((row) => row);

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        {canUpdate && (
          <CmsUploadButton
            accept="video/*"
            customRequest={(options) => {
              props.onAdd(options.file, props.videos.length);
            }}
          />
        )}
      </div>
      <div style={{ maxWidth: "60%" }}>
        <Table
          bordered
          size={"small"}
          dataSource={props.videos}
          rowKey={(row, index) => row.id || (index as number)}
          pagination={false}
          columns={columns as ColumnsType<Video>}
          rowSelection={undefined}
        />
      </div>
    </>
  );
}
