import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Province } from "../../models/reference_lists/Province";
import { CONST_PROVINCES } from "../../utilities/constants/query-contants";

export const getProvinces = (): Promise<Province[]> => {
  return api.get({
    path: CONST_PROVINCES.plural,
  });
};

export const useQueryProvinces = () => {
  return useQuery<Province[]>([CONST_PROVINCES.plural], () => getProvinces());
};
