import { Instruction } from "../data/Instruction";
import { MultilingualInputData } from "../MultilingualInputData";
import { AttributeLink } from "./AttributeLink";

export class SecurityFeature {
  public id?: string;
  public groupId?: string;
  public name?: string;
  public description: MultilingualInputData = {};
  public caption: MultilingualInputData = {};
  public code?: string;
  public interactionType: InteractionType = InteractionType.None;
  public fileId?: string;
  public instructions: Instruction[] = [];
  public attributeLinks: AttributeLink[] = [];

  public withGroup(groupId: string) {
    this.groupId = groupId;
    return this;
  }
}

export enum InteractionType {
  None = "NONE",
  Look = "LOOK",
  Feel = "FEEL",
  Tilt = "TILT",
}
