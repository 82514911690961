import { Button, Layout, Modal, Table } from "antd";
import { CmsButton } from "../../../common/ButtonComponents";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import ReactLassoSelect from "react-lasso-select";
import { useEffect, useRef, useState } from "react";
import { FieldValue } from "../../../../models/data/FieldValue";
import { Point } from "react-lasso-select/lib/helpers";
import { useTranslation } from "react-i18next";
import "./FieldValues.css";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { CropperModal } from "./CropperModal";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import { constructFieldValues } from "../../../../utilities/helpers/field-helper";
import { RowButtonSet } from "../../../common/RowButtonSet";
import { PolyCropperModal } from "./PolyCropperModal";

export default function FieldValuesDetailsModal({
  open,
  headerName,
  src,
  orientation,
  imageId,
  shapeType,
  selection,
  onClose,
  onSave,
  onChange,
  isSubmitting,
}: {
  open: boolean;
  headerName: string;
  src: string;
  orientation: number;
  imageId: string;
  shapeType?: "RECTANGLE" | "POLYGON";
  selection: FieldProps;
  onClose: () => void;
  onSave: () => void;
  onChange: (selection: FieldValue[]) => void;
  isSubmitting: boolean;
}) {
  const { t } = useTranslation();
  const [copperModal, setCopperModal] = useState<{
    open: boolean;
    selection?: Cropper.Data;
    updated?: Cropper.Data;
  }>({
    open: false,
    selection: undefined,
    updated: undefined,
  });
  const [polyModal, setPolyModal] = useState<{ open: boolean; selection?: Point[] }>({
    open: false,
    selection: undefined,
  });
  const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
  const [polyState, setPolyState] = useState<Point[] | undefined>(undefined);
  const [rectState, setRectState] = useState<Cropper.Data | undefined>(undefined);

  const cropperRef = useRef<ReactCropperElement>(null);

  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    natWidth: number;
    natHeight: number;
  }>({ width: 0, height: 0, natWidth: 0, natHeight: 0 });

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      setImageSize({
        width: image.width,
        height: image.height,
        natHeight: image.naturalHeight,
        natWidth: image.naturalWidth,
      });
    };
  }, []);

  const destructRect = (fieldValue: FieldValue) => {
    return {
      height: (fieldValue?.height ?? 1) / imageSize.height,
      width: (fieldValue?.width ?? 1) / imageSize.width,
      x: (fieldValue?.x ?? 1) / imageSize.width,
      y: (fieldValue?.y ?? 1) / imageSize.height,
    };
  };

  const destructPoly = (points: Point[]) => {
    return {
      points: points?.map((point) => ({
        x: point.x / imageSize.width,
        y: point.y / imageSize.height,
      })),
    };
  };

  const construct = (fieldValue: FieldValue, type: "rect" | "poly", modal: boolean = false) => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      if (type === "rect") {
        const data = {
          height: image.height * (fieldValue?.height ?? 1),
          width: image.width * (fieldValue?.width ?? 1),
          x: image.width * (fieldValue?.x ?? 1),
          y: image.height * (fieldValue?.y ?? 1),
          rotate: orientation,
          scaleX: 1,
          scaleY: 1,
        } as Cropper.Data;

        if (modal) {
          setCopperModal({
            open: true,
            selection: data,
          });
        }

        setRectState(data);
      }

      if (type === "poly") {
        const poly = fieldValue.points?.map((point) => ({
          x: image.width * point.x,
          y: image.height * point.y,
        }));

        if (modal) {
          setPolyModal({
            open: true,
            selection: fieldValue.points?.map((point) => ({
              x: image.width * point.x,
              y: image.height * point.y,
            })),
          });
        }

        setPolyState(poly);
      }
    };
  };

  const saveAndReset = () => {
    onChange(constructFieldValues(selection.fieldValues, selection.fieldLinkId, imageId));
    onSave();

    setEditIndex(undefined);
    setCopperModal({ open: false, selection: undefined });
    setPolyModal({ open: false, selection: undefined });
    setPolyState(undefined);
    setRectState(undefined);
  };

  return (
    <>
      <Modal
        width={1000}
        style={{ height: "750px", maxHeight: "80%" }}
        title={headerName}
        open={open}
        closable={true}
        onCancel={() => {
          onClose();
          setEditIndex(undefined);
          setPolyState(undefined);
          setRectState(undefined);
        }}
        footer={[
          <CmsButton
            buttonType="default"
            key="cancel"
            onClick={() => {
              onClose();
              setEditIndex(undefined);
              setPolyState(undefined);
              setRectState(undefined);
            }}
          >
            Ok
          </CmsButton>,
        ]}
        onClose={() => {
          onClose();
          setEditIndex(undefined);
          setPolyState(undefined);
          setRectState(undefined);
        }}
      >
        <Layout
          style={{
            borderRadius: 8,
            overflow: "hidden",
            width: "100%",
            maxWidth: "100%",
            minHeight: "600px",
          }}
        >
          <Sider
            width="50%"
            style={{
              position: "relative",
              textAlign: "center",
              lineHeight: "120px",
              color: "#fff",
              backgroundColor: "#f5f5f5",
              justifyContent: "center",
              maxHeight: "600px",
              maxWidth: "470px",
              overflow: "hidden",
            }}
          >
            <div className={"previewContainer"}>
              {src ? (
                <>
                  {polyState !== undefined && (
                    <div style={{ maxWidth: "100%", maxHeight: "600px" }}>
                      <ReactLassoSelect
                        src={src}
                        value={polyState}
                        imageStyle={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </div>
                  )}
                  {rectState !== undefined && (
                    <ReactCropper
                      ref={cropperRef}
                      id="selection-area"
                      preview={".preview"}
                      src={src}
                      dragMode="none"
                      background={false}
                      autoCrop={!!rectState}
                      viewMode={2}
                      style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
                      data={rectState}
                      scalable={false}
                      zoomable={false}
                      draggable={false}
                      movable={false}
                      disabled
                    />
                  )}
                  {rectState === undefined && polyState === undefined && (
                    <img
                      src={src}
                      style={{ maxWidth: "100%", maxHeight: "600px" }}
                      alt="document-image"
                    />
                  )}
                </>
              ) : (
                <img
                  src="/assets/images/keesing-logo.jpg"
                  style={{ minWidth: "100%" }}
                  alt="Keesing logo"
                />
              )}
            </div>
          </Sider>
          <Layout>
            <Content
              style={{
                textAlign: "center",
                minHeight: 50,
                lineHeight: "50px",
                color: "#fff",
              }}
            >
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: "0px 2px" }}
                size="small"
                onClick={() => setCopperModal({ open: true, selection: undefined })}
                disabled={isSubmitting}
              >
                Rectangle
              </Button>
              <Button
                title="Currently not in use"
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: "0px 2px" }}
                size="small"
                onClick={() => setPolyModal({ open: true, selection: undefined })}
                disabled
              >
                Polygon
              </Button>
            </Content>
            <Content
              style={{
                textAlign: "center",
                color: "#fff",
                height: "100%",
                paddingInline: 48,
                lineHeight: "64px",
              }}
            >
              <div style={{ position: "relative", height: "200px", width: "350px" }}>
                {!!rectState && (
                  <div className="previewContainer">
                    <div
                      className="preview"
                      style={{
                        maxHeight: "200px",
                        maxWidth: "350px",
                        height: rectState?.height,
                        width: rectState?.width,
                      }}
                    />
                  </div>
                )}
              </div>
              <Table
                dataSource={selection?.fieldValues}
                loading={isSubmitting}
                columns={[
                  {
                    title: "",
                    render: (_, fieldValue, index) => (
                      <div>{index === editIndex && <CheckOutlined />}</div>
                    ),
                  },
                  {
                    title: "",
                    render: (_, fieldValue, index) => (
                      <RowButtonSet
                        onEdit={() => {
                          switch (fieldValue.shapeType) {
                            case "RECTANGLE":
                              construct(fieldValue, "rect", true);
                              break;
                            case "POLYGON":
                              construct(fieldValue, "poly", true);
                              break;
                            default:
                              break;
                          }
                          setEditIndex(index);
                        }}
                      />
                    ),
                  },
                  {
                    title: t("properties:selection"),
                    render: (text, record, index) => (
                      <div style={{ width: "140px" }}>#{index + 1}</div>
                    ),
                  },
                  {
                    title: "",
                    render: (_, fieldValue, index) => (
                      <RowButtonSet
                        onDelete={() => {
                          setRectState(undefined);
                          setPolyState(undefined);
                          setEditIndex(undefined);

                          const fieldValues = selection.fieldValues.slice(0);
                          fieldValues.splice(index, 1);

                          onChange(
                            constructFieldValues(fieldValues, selection.fieldLinkId, imageId),
                          );
                          onSave();
                        }}
                      />
                    ),
                  },
                ]}
                onRow={(fieldValue, index) => ({
                  onClick: () => {
                    setRectState(undefined);
                    setEditIndex(undefined);

                    if (index === editIndex) {
                      setRectState(undefined);
                    } else {
                      switch (fieldValue.shapeType) {
                        case "RECTANGLE":
                          construct(
                            {
                              height: fieldValue.height ?? 0,
                              width: fieldValue.width ?? 0,
                              x: fieldValue.x ?? 0,
                              y: fieldValue.y ?? 0,
                            },
                            "rect",
                          );
                          break;
                        case "POLYGON":
                          construct(fieldValue, "poly");
                          break;
                        default:
                          break;
                      }

                      setEditIndex(index);
                    }
                  },
                })}
              />
            </Content>
          </Layout>
        </Layout>
      </Modal>
      {copperModal.open && (
        <CropperModal
          file={src}
          open={copperModal.open}
          title={headerName}
          isSubmitting={isSubmitting}
          onClose={() => setCopperModal({ open: false, selection: undefined })}
          onSave={(value, another) => {
            const newValue = destructRect(value);

            if (editIndex !== undefined && selection.fieldValues[editIndex] !== undefined) {
              selection.fieldValues[editIndex].height = newValue.height;
              selection.fieldValues[editIndex].width = newValue.width;
              selection.fieldValues[editIndex].x = newValue.x;
              selection.fieldValues[editIndex].y = newValue.y;
            } else {
              selection.fieldValues.push({
                height: newValue.height,
                width: newValue.width,
                x: newValue.x,
                y: newValue.y,
                shapeType: "RECTANGLE",
              });
            }

            saveAndReset();

            if (another) {
              setCopperModal({ open: true, selection: undefined });
            }
          }}
          preSelection={copperModal.selection}
          multiple
        />
      )}
      {polyModal.open && (
        <PolyCropperModal
          fileId={src}
          open={polyModal.open}
          onClose={() => setPolyModal({ open: false, selection: undefined })}
          onSave={(value) => {
            const newValue = destructPoly(value);

            if (editIndex !== undefined && selection.fieldValues[editIndex] !== undefined) {
              selection.fieldValues[editIndex].points = newValue.points;
            } else {
              selection.fieldValues.push({
                points: newValue.points,
                shapeType: "POLYGON",
              });
            }

            saveAndReset();
          }}
          preSelection={polyModal.selection}
        />
      )}
    </>
  );
}

interface FieldProps {
  fieldLinkId: string;
  fieldName: string;
  fieldValues: FieldValue[];
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  shapeType: "RECTANGLE" | "POLYGON";
  points?: Point[];
}
