import { CmsDownloadButton } from "../common/ButtonComponents";
import { CmsPageHeader } from "../common/PageComponents";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CmsNumber } from "../common/FormComponents";
import { useMagazineExport } from "../../queries/document-series/detail";

export const MagazineExport = () => {
  const { t } = useTranslation();
  const [exportYear, setExportYear] = useState<number>(new Date().getFullYear());
  const { mutateAsync: download, isLoading: isDownloading } = useMagazineExport(
    exportYear.toString(),
  );

  return (
    <>
      <CmsPageHeader title={t("common:magazineExport")} />
      <CmsNumber
        id="magazineExport"
        label={t("properties:year")}
        placeholder={t("properties:year")}
        value={exportYear}
        onChange={(value) => setExportYear(value == null ? 2024 : value)}
      />
      <p>{t("common:magazineExportExplaination")}</p>
      <CmsDownloadButton
        key="download"
        disabled={isDownloading}
        onClick={async () => await download()}
      />
    </>
  );
};
