import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, TreeSelect } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { default as config } from "../../../Config";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { MultilingualInputData } from "../../../models/MultilingualInputData";
import { AttributeLink } from "../../../models/templates/AttributeLink";
import { InteractionType, SecurityFeature } from "../../../models/templates/SecurityFeature";
import { convertGroupsForTreeSelect } from "../../../utilities/GroupHelper";
import { addItem, OrderableItem, rearrangeItems, removeItem } from "../../../utilities/ListHelpers";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import {
  CmsBackButton,
  CmsDeleteButton,
  CmsSaveButton,
  CmsUploadButton,
} from "../../common/ButtonComponents";
import { DragSortTable } from "../../common/DragSortTable";
import { CmsForm, CmsSelect } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { AttributeLinkDetailsModal } from "../attributeLinks/AttributeLinkDetailsModal";
import { InstructionsList } from "../../data/documents/InstructionsList";
import {
  useQuerySecurityFeature,
  useSaveSecurityFeature,
} from "../../../queries/security-features/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useUploadFile } from "../../../queries/files/detail";
import { useQueryGroups } from "../../../queries/groups/lists";
import { GroupType } from "../../../queries/groups/group-types";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";

export const SecurityFeatureDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("securityFeature");
  const { push } = useHistory();
  const { id: securityFeatureId } = useParams<Entity>();
  const context = useCmsContext();
  const { required, maxLength, isValidName, isValidCode } = useRules();

  const [state, setState] = React.useState<{
    selectedAttributeLink: AttributeLink | null;
    selectedAttributeLinkIndex: number | null;
    attributeLinkModalVisible: boolean;
    selectedInstructionIndex: number | null;
    showInstructionSelection: boolean;
  }>({
    selectedAttributeLink: null,
    selectedAttributeLinkIndex: null,
    attributeLinkModalVisible: false,
    selectedInstructionIndex: null,
    showInstructionSelection: false,
  });

  const {
    data: securityFeature,
    isLoading: isLoadingSecurityFeature,
    refetch: refetchSecurityFeature,
  } = useQuerySecurityFeature(securityFeatureId);
  const { mutateAsync: saveSecurityFeature, isLoading: isLoadingSaveSecurityFeature } =
    useSaveSecurityFeature();
  const { data: groups, isLoading: isLoadingSecurityFeatureGroups } = useQueryGroups(
    GroupType.SecurityFeature,
  );
  const { mutateAsync: uploadFile, isLoading: isImportingNote } = useUploadFile();

  useEffect(() => {
    if (securityFeature) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateSecurityFeature", {
          name: securityFeature.name,
          code: securityFeature.code,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "currency",
          name: securityFeature.name ?? t("common:new"),
        },
      ]);
    }
  }, [securityFeature, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
    setValue,
    getValues,
  } = useForm<SecurityFeature>({
    mode: "onChange",
    values: securityFeature,
  });

  const noDataAny = (!securityFeature && !!securityFeatureId) || !groups;
  const isLoadingAny =
    isLoadingSecurityFeatureGroups ||
    (isLoadingSecurityFeature && !!securityFeatureId) ||
    isLoadingSaveSecurityFeature ||
    isImportingNote;

  const submit = async (securityFeature: SecurityFeature) => {
    await saveSecurityFeature(securityFeature);
    !securityFeature.id ? push(`/security-features`) : await refetchSecurityFeature();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <>
      <CmsForm>
        <Prompt when={isDirty} message={t("common:unsavedChanges")} />
        <CmsPageHeader
          title={t("entities:securityFeature")}
          extra={[
            <CmsBackButton
              key="back"
              disabled={isLoadingAny}
              onClick={() => push(`/security-features`)}
            />,
            !!canUpdate && (
              <CmsSaveButton
                key="save"
                disabled={!isValid || isLoadingAny}
                loading={isSubmitting}
                onClick={handleSubmit(submit)}
              />
            ),
          ]}
        />

        <Form.Item label={t("properties:group")}>
          <TreeSelect
            style={{ width: "100%", marginTop: "5px" }}
            placeholder={t("properties:group")}
            disabled={!canUpdate || isLoadingAny}
            treeDefaultExpandAll={true}
            treeData={convertGroupsForTreeSelect(groups)}
            value={getValues().groupId}
            onChange={(value) =>
              setValue("groupId", value, {
                shouldTouch: true,
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        </Form.Item>

        <ControlledInput
          control={control}
          name={"name"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:name")}
          rules={{
            ...required(t("properties:name")),
            ...maxLength(75, t("properties:name")),
            ...isValidName(t("properties:name")),
          }}
        />
        <ControlledMultilingualText
          control={control}
          name={"caption"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:caption")}
        />
        <ControlledInput
          control={control}
          name={"code"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:code")}
          rules={{
            ...required(t("properties:code")),
            ...maxLength(75, t("properties:code")),
            ...isValidCode(t("properties:code")),
          }}
        />

        {/* TODO: change this to controller variant when ant 6 is installed */}
        <CmsSelect
          label={t("properties:interactionType")}
          placeholder={t("properties:interactionType")}
          disabled={isLoadingAny}
          readOnly={!canUpdate}
          loading={isLoadingAny}
          error={errors?.interactionType}
          value={getValues().interactionType}
          allowClear={false}
          onChange={(value) =>
            setValue("interactionType", value, {
              shouldTouch: true,
              shouldDirty: true,
              shouldValidate: true,
            })
          }
        >
          {Object.values(InteractionType).map((key, value) => (
            <Select.Option key={key} value={value}>
              {capitalizeFirstLetter(key.toLowerCase())}
            </Select.Option>
          ))}
        </CmsSelect>

        <ControlledMultilingualText
          multiLine
          control={control}
          name={"description"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:description")}
        />
        <Form.Item label={t("entities:attributes")}>
          <DragSortTable
            bordered
            size={"small"}
            onRow={() =>
              ({
                moveRow: (fromIndex, toIndex) =>
                  setValue(
                    "attributeLinks",
                    rearrangeItems(
                      getValues().attributeLinks as OrderableItem[],
                      fromIndex,
                      toIndex,
                    ),
                    {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    },
                  ),
              }) as any
            }
            columns={[
              {
                title: t("entities:attribute"),
                dataIndex: "attributeName",
              },
              {
                title: t("properties:caption"),
                dataIndex: "caption",
                render: (caption: MultilingualInputData) => {
                  if (caption) {
                    return <span>{getLocalizedValue(caption)}</span>;
                  } else {
                    return null;
                  }
                },
              },
              {
                title: t("properties:exportable"),
                dataIndex: "exportable",
                render: (value) => (value ? t("common:yes") : t("common:no")),
              },
              {
                title: t("properties:required"),
                dataIndex: "isRequired",
                render: (value) => (value ? t("common:yes") : t("common:no")),
              },
              ...(!!canUpdate
                ? [
                    {
                      title: (
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<PlusOutlined />}
                          disabled={isLoadingAny}
                          style={{ margin: "0px 2px" }}
                          size="small"
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: new AttributeLink(),
                              selectedAttributeLinkIndex: -1,
                              attributeLinkModalVisible: true,
                            }));
                          }}
                        />
                      ),
                      dataIndex: "actions",
                      align: "right" as any,
                      render: (text: any, record: any, index) => (
                        <RowButtonSet
                          onEdit={() =>
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: record,
                              attributeLinkModalVisible: true,
                              selectedAttributeLinkIndex: index,
                            }))
                          }
                          onDelete={() => {
                            const attributeLinks = removeItem(
                              index,
                              getValues().attributeLinks as OrderableItem[],
                            );
                            setValue("attributeLinks", attributeLinks, {
                              shouldTouch: true,
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            setState((prevState) => ({
                              ...prevState,
                              selectedAttributeLink: null,
                              selectedAttributeLinkIndex: -1,
                            }));
                          }}
                        />
                      ),
                    },
                  ]
                : []),
            ]}
            dataSource={getValues().attributeLinks as OrderableItem[]}
            rowKey={(row, index) => row.order || (index as any)}
            pagination={false}
          />
        </Form.Item>
        <Form.Item label={t("properties:texts")}>
          <InstructionsList
            key={"securityFeatureInstructionsList"}
            instructionsList={getValues().instructions}
            onChange={(instructions) =>
              setValue("instructions", instructions, {
                shouldTouch: true,
                shouldDirty: true,
                shouldValidate: true,
              })
            }
          />
        </Form.Item>
        <Form.Item label={t("properties:exampleImage")}>
          {getValues().fileId && (
            <div style={{ marginBottom: "10px" }}>
              <img
                src={`${config.apiUrl}file/${getValues().fileId}?timeStamp=${new Date().getTime()}`}
                alt="example"
                style={{ maxWidth: "400px", maxHeight: "400px" }}
              />
            </div>
          )}
          <div>
            <CmsUploadButton
              accept="image/*"
              customRequest={async (options) => {
                const response = await uploadFile({ file: options.file, id: getValues().fileId });

                if (response.id !== getValues().fileId) {
                  setValue(`fileId`, response.id, {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }
              }}
            />
            {getValues().fileId ? (
              <CmsDeleteButton
                style={{ marginLeft: "10px" }}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  setValue(`fileId`, undefined, {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              />
            ) : null}
          </div>
        </Form.Item>
      </CmsForm>
      {state.attributeLinkModalVisible && (
        <AttributeLinkDetailsModal
          attributeLink={state.selectedAttributeLink as AttributeLink}
          onSave={(attributeLink) => {
            let attributeLinks = getValues().attributeLinks;

            if (!attributeLinks) return;

            if (state.selectedAttributeLinkIndex && state.selectedAttributeLinkIndex >= 0) {
              attributeLinks[state.selectedAttributeLinkIndex] = attributeLink;
            } else {
              attributeLinks = addItem(
                attributeLink as OrderableItem,
                attributeLinks as OrderableItem[],
              ) as AttributeLink[];
            }

            setValue("attributeLinks", attributeLinks);

            setState((prevState) => ({
              ...prevState,
              selectedAttributeLink: null,
              selectedAttributeLinkIndex: -1,
              attributeLinkModalVisible: false,
            }));
          }}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              selectedAttributeLink: null,
              selectedAttributeLinkIndex: -1,
              attributeLinkModalVisible: false,
            }));
          }}
        />
      )}
    </>
  );
};
