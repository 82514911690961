import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Region } from "../../../models/reference_lists/Region";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { useQueryRegions } from "../../../queries/regions/lists";
import { useDeleteRegion } from "../../../queries/regions/detail";
import { useCmsContext } from "../../../context/app/CmsContext";

export const RegionList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } = useAuthorization("region");
  const { push } = useHistory();
  const context = useCmsContext();
  const { data: regions, isLoading: isLoadingRegions, refetch: refetchRegions } = useQueryRegions();
  const { mutateAsync: deleteRegion, isLoading: isDeletingRegion } = useDeleteRegion();

  const [regionsFilter, setRegionsFilter] = useState<Region[]>(regions ?? []);

  useEffect(() => {
    if (!!regions) {
      setRegionsFilter(regions);
    }
  }, [regions]);

  useEffect(() => {
    if (regionsFilter) {
      context?.setDuplicateErrorMessage(t("errors:regionLinked"));
    }
  }, [regionsFilter, t]);

  const search = (searchText: any) => {
    if (regions) {
      const search = searchText.toLowerCase();
      const filteredRegions = regions.filter((region) => {
        const name = getLocalizedValue(region.name)?.toLowerCase();
        const iso = region.isoCode && region.isoCode?.toLowerCase();
        return (name && name.includes(search)) || (iso && iso.includes(search));
      });

      setRegionsFilter(filteredRegions);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:regions")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? <CmsCreateButton key="create" onClick={() => push(`/region`)} /> : null,
        ]}
      />
      <CmsTable
        loading={isLoadingRegions || isDeletingRegion}
        dataSource={regionsFilter}
        rowKey="id"
        columns={[
          {
            title: t("properties:name"),
            dataIndex: "name",
            render: (name) => getLocalizedValue(name),
          },
          { title: t("properties:isoCode"), dataIndex: "isoCode" },
        ]}
        onEdit={canUpdate || canView ? (id) => push(`/region/${id}`) : undefined}
        onDelete={
          canDelete
            ? async (id) => {
                await deleteRegion(id);
                await refetchRegions();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
