import { LoadingOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Radio, Select, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentTemplate } from "../../../../models/templates/DocumentTemplate";
import styles from "./DocumentWizard.module.scss";
import { useQuerySearch } from "../../../../queries/search/detail";
const { Title } = Typography;

export enum CreationMode {
  NewDocument = 0,
  CopyDocument = 1,
}

type WizardSearchResult = {
  id: string;
  code: string;
};

export const StepTemplate = (props: {
  documentTemplates: DocumentTemplate[];
  documentTemplateId?: string;
  documentId?: string;
  documentCode?: string;
  creationMode: CreationMode;
  onNext: (creationMode: CreationMode, documentTemplateId?: string, documentId?: string) => void;
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    creationMode: CreationMode;
    documentTemplateId?: string;
    documentId?: string;
    documentSearchText?: string;
  }>({
    creationMode: props.creationMode,
    documentTemplateId: props.documentTemplateId,
    documentId: props.documentId,
    documentSearchText: props.documentCode,
  });

  const { data: documentSearchResults, isLoading: isLoadingSearch } = useQuerySearch(
    `code: ${state.documentSearchText}*`,
  );

  return (
    <>
      <div className={`${styles["step-content"]} ${styles["step-template"]}`}>
        <Title level={3}>{t("common:template")}</Title>
        <div className={styles["step-text"]}>{t("texts:newDocumentStep1")}</div>
        <Radio.Group
          className={styles["creation-mode"]}
          value={state.creationMode}
          onChange={(event) =>
            setState((prevState) => ({
              ...prevState,
              creationMode: event.target.value,
              documentId: undefined,
              documentTemplateId: undefined,
            }))
          }
        >
          <Radio value={CreationMode.NewDocument}>{t("texts:newDocumentStep1NewDocument")}</Radio>
          <Radio value={CreationMode.CopyDocument}>{t("texts:newDocumentStep1CopyDocument")}</Radio>
        </Radio.Group>
        {state.creationMode === CreationMode.NewDocument && (
          <>
            <div>{t("texts:newDocumentStep1ChooseDocumentTemplate")}:</div>
            <Select
              style={{ width: "350px" }}
              value={state.documentTemplateId}
              onChange={(documentTemplateId) =>
                setState((prevState) => ({
                  ...prevState,
                  documentTemplateId: documentTemplateId,
                }))
              }
            >
              {props.documentTemplates.map((documentTemplate, index) => (
                <Select.Option key={index} value={documentTemplate.id ?? ""}>
                  {documentTemplate.name}
                </Select.Option>
              ))}
            </Select>
          </>
        )}
        {state.creationMode === CreationMode.CopyDocument && (
          <>
            <div>Zoek een document:</div>
            <AutoComplete
              placeholder={t("common:search")}
              options={documentSearchResults?.map((document: WizardSearchResult) => ({
                value: document.code,
                key: document.id,
              }))}
              value={state.documentSearchText}
              // onKeyUp={() => searchDocuments()}
              onChange={(value) =>
                setState((prevState) => ({
                  ...prevState,
                  documentSearchText: value,
                }))
              }
              onSelect={(_, item) =>
                setState((prevState) => ({
                  ...prevState,
                  documentId: item.key?.toString(),
                }))
              }
            >
              <Input
                suffix={
                  isLoadingSearch ? (
                    <LoadingOutlined className="certain-category-icon" spin />
                  ) : (
                    <SearchOutlined className="certain-category-icon" />
                  )
                }
              />
            </AutoComplete>
          </>
        )}
      </div>
      <div className={styles["button-bar"]}>
        <Button
          className={"button-next"}
          type="primary"
          icon={<RightOutlined />}
          disabled={
            !(
              (state.creationMode === CreationMode.NewDocument && state.documentTemplateId) ||
              (state.creationMode === CreationMode.CopyDocument && state.documentId)
            )
          }
          onClick={() => {
            props.onNext(state.creationMode, state.documentTemplateId, state.documentId);
          }}
        >
          {t("common:next")}
        </Button>
      </div>
    </>
  );
};
