import { useQuery } from "@tanstack/react-query";
import { Attribute } from "../../models/templates/Attribute";
import api from "../../services/api";
import { CONST_ATTRIBUTES } from "../../utilities/constants/query-contants";
import { AttributeFilter } from "./types";

export const getAttributes = (payload?: AttributeFilter): Promise<Attribute[]> => {
  return api.get({
    path: CONST_ATTRIBUTES.plural,
    query: { ...payload },
  });
};

export const useQueryAttributes = (payload?: AttributeFilter) => {
  return useQuery<Attribute[]>([CONST_ATTRIBUTES.plural, { payload }], () =>
    getAttributes(payload),
  );
};
