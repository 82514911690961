import {
  DeleteOutlined,
  PlusOutlined,
  DragOutlined,
  FileSyncOutlined,
  FileMarkdownOutlined,
} from "@ant-design/icons";
import { Button, Divider, Popconfirm, Select } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Instruction, InstructionRelatesTo } from "../../../models/data/Instruction";
import { MultilingualInputData } from "../../../models/MultilingualInputData";
import { DragSortTable } from "../../common/DragSortTable";
import { MultilingualInput } from "../../common/MultilingualInput";
import { rearrangeItems } from "../../../utilities/ListHelpers";
import { CmsSelect } from "../../common/FormComponents";
import { upperFirst } from "lodash";
import { useCmsContext } from "../../../context/app/CmsContext";

export const InstructionsList = ({
  instructionsList,
  onChange,
  richText = false,
  instructionLocationOptions = ["NOT_SPECIFIED"],
  defaultInstructionLocationOption = "NOT_SPECIFIED",
}: {
  instructionsList?: Instruction[];
  onChange(instructions: Instruction[]);
  richText?: boolean;
  instructionLocationOptions?: InstructionRelatesTo[];
  defaultInstructionLocationOption?: InstructionRelatesTo;
}) => {
  const context = useCmsContext();
  const { canUpdate, isAdmin } = useAuthorization("document");
  const [locationOption, setLocationOption] = useState<InstructionRelatesTo>(
    defaultInstructionLocationOption,
  );

  useEffect(() => {
    const getAutoSaveModeSetting = localStorage.getItem("instructionAutosave");
    if (getAutoSaveModeSetting != null) {
      context?.setAutoSave(isAdmin ? JSON.parse(getAutoSaveModeSetting) === true : true);
    }
  }, [isAdmin]);

  const changeInstructionAutoSaveMode = (enabled: boolean) => {
    context?.setAutoSave(enabled);
    localStorage.setItem("instructionAutosave", enabled.toString());
  };

  const { t } = useTranslation();

  const arrangeOrderNumbers = (instructions: Instruction[]) =>
    instructions.forEach((instruction, index) => {
      instruction.order = index;
    });

  const addInstruction = () => {
    const initInstructions = instructionsList ?? [];

    const highestOrder = initInstructions.reduce((highest, instruction) => {
      return instruction.order > highest ? instruction.order : highest;
    }, 0);

    const newInstruction = {
      caption:
        locationOption === "NOT_SPECIFIED"
          ? {}
          : {
              en: "Notification",
              fr: "Notification",
              nl: "Kennisgeving",
              de: "Benachrichtigung",
              it: "Notifica",
              es: "Notificación",
              ru: "Уведомление",
              zh: "通知",
            },
      text: {},
      relatesTo: locationOption,
      order: highestOrder + 1,
    } as Instruction;

    onChange([...initInstructions, newInstruction]);
  };

  const updateMultilanguage = (
    index: number,
    value: MultilingualInputData,
    type: "caption" | "text",
  ) => {
    const instructions = instructionsList?.map((instruction, i) => {
      if (instruction.order === index) {
        return {
          ...instruction,
          [type]: value,
        };
      }
      return instruction;
    });

    instructions && onChange(instructions);
  };

  const updateInstructions = (fromIndex: number, toIndex: number) => {
    if (!instructionsList) return;

    const rearrangedInstructions = rearrangeItems(instructionsList, fromIndex, toIndex);
    onChange(rearrangedInstructions);
  };

  const removeInstruction = (index: number) => {
    const instructions = instructionsList?.filter((instruction) => instruction.order !== index);

    if (!instructions) return;

    arrangeOrderNumbers(instructions);

    onChange(instructions);
  };

  useEffect(() => {
    if (!instructionsList || instructionsList.length === 0) return;

    const hasDuplicateOrder =
      new Set(instructionsList.map((instruction) => instruction.order)).size !==
      instructionsList.length;

    if (hasDuplicateOrder) {
      arrangeOrderNumbers(instructionsList);

      onChange(instructionsList);
    }
  }, []);

  const columns = [
    {
      title: isAdmin && (
        <Button
          ghost={!context?.autoSave}
          title={t("instructionsAutoSave:description", {
            status: context?.autoSave
              ? t("instructionsAutoSave:enabled")
              : t("instructionsAutoSave:disabled"),
          })}
          type="primary"
          key="new"
          shape="circle"
          icon={context?.autoSave ? <FileSyncOutlined /> : <FileMarkdownOutlined />}
          size="large"
          onClick={() => changeInstructionAutoSaveMode(!context?.autoSave)}
        />
      ),
      dataIndex: "dragger",
      width: "50px",
      render: () => (
        <div style={{ display: "flex" }}>
          <DragOutlined style={{ fontSize: "30px", marginTop: "40px", width: "40px" }} />
        </div>
      ),
    },
    {
      title: t("properties:caption"),
      dataIndex: "caption",
      width: "45%",
      render: (text, instruction: Instruction) => (
        <div>
          {instruction.relatesTo &&
            !["NOT_SPECIFIED", "DOCUMENT_NOTIFICATION"].includes(instruction.relatesTo) && (
              <div style={{ fontSize: "20px", padding: "5px" }}>
                <Divider orientation="left" style={{ fontSize: 12, color: "#1677ff" }}>
                  {`Location: ${upperFirst(
                    instruction.relatesTo.replace("_COUNTRY", "").toLowerCase(),
                  )}`}
                </Divider>
              </div>
            )}
          <MultilingualInput
            readOnly={!canUpdate}
            mlData={text}
            onChange={(value) => updateMultilanguage(instruction.order, value, "caption")}
            disabled={instruction.relatesTo !== "NOT_SPECIFIED"}
            compareList={true}
            autoSave={isAdmin ? context?.autoSave : false}
          />
        </div>
      ),
    },
    {
      title: (
        <div style={{ display: "flex" }}>
          {t("properties:text")}
          {instructionLocationOptions.length > 1 && (
            <div style={{ marginLeft: "auto", minWidth: "300px" }}>
              <CmsSelect
                label={t("properties:instructionLocation")}
                value={locationOption}
                allowClear={false}
                showSearch={false}
                onChange={(value) => setLocationOption(() => value)}
                children={instructionLocationOptions.map((option, index) => (
                  <Select.Option key={`field_${index}`} value={option}>
                    {upperFirst(
                      option
                        .replace("_COUNTRY", "")
                        .replace("DOCUMENT_", "")
                        .replace("_", " ")
                        .toLowerCase(),
                    )}
                  </Select.Option>
                ))}
              />
            </div>
          )}
        </div>
      ),
      dataIndex: "text",
      render: (text, instruction) => (
        <MultilingualInput
          readOnly={!canUpdate}
          mlData={text}
          multiLine={true}
          onChange={(value) => updateMultilanguage(instruction.order, value, "text")}
          compareList={true}
          richEditor={richText}
          autoSave={isAdmin ? context?.autoSave : false}
        />
      ),
    },
    canUpdate && {
      title: (
        <Button
          type="primary"
          key="new"
          shape="circle"
          icon={<PlusOutlined />}
          size="large"
          onClick={addInstruction}
        />
      ),
      width: 10,
      align: "right",
      render: (text: any, record: any, index: number) => (
        <Popconfirm
          title={t("common:confirmDelete")}
          okText={t("common:yes")}
          cancelText={t("common:no")}
          onConfirm={() => removeInstruction(record.order)}
        >
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            shape="circle"
            size="large"
            style={{ marginTop: "3px" }}
          />
        </Popconfirm>
      ),
    },
  ] as ColumnsType<Instruction>;

  return (
    <React.Fragment>
      {canUpdate ? (
        <DragSortTable
          bordered
          size={"small"}
          onRow={(record, index) =>
            ({
              moveRow: (fromIndex, toIndex) => updateInstructions(fromIndex, toIndex),
            }) as any
          }
          columns={columns}
          dataSource={instructionsList}
          rowKey={(row, index) => row.id || (index as number)}
          pagination={false}
        />
      ) : (
        <Table
          bordered
          size={"small"}
          dataSource={instructionsList}
          rowKey={(row, index) => row.id || (index as number)}
          pagination={false}
          columns={columns}
          rowSelection={undefined}
        />
      )}
    </React.Fragment>
  );
};
