import { UploadOutlined } from "@ant-design/icons";
import { Affix, Checkbox, Col, InputNumber, Layout, Row, Tabs } from "antd";
import * as _ from "lodash";
import Dragger from "antd/lib/upload/Dragger";
import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { ImageTemplate } from "../../../models/templates/ImageTemplate";
import { CmsButton, CmsDeleteButton, CmsUploadButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem, CmsText } from "../../common/FormComponents";
import { CmsContent, CmsPageLoader, CmsTabs } from "../../common/PageComponents";
import { AddSecurityFeatureModal } from "./AddSecurityFeatureModal";
import { DocumentsImageInstruction } from "./DocumentsImageInstructions";
import ReactLassoSelect from "react-lasso-select";
import { FieldList } from "./FieldList";
import "./ImageDetails.scss";
import { SecurityFeatureList } from "./SecurityFeatureList";
import { Point } from "react-lasso-select/lib/helpers";
import VideoManagement from "./VideoManagement";
import { Video } from "../../../models/data/Video";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { generateSecurityFeatureSelectionPlaceholder } from "../../../utilities/SecurityFeatureSelectionHelper";
import { Image as ImageType } from "../../../models/data/Image";
import { RcFile } from "antd/lib/upload/interface";
import { useQueryFile, useUploadFile } from "../../../queries/files/detail";
import { useDeleteVideo, useSaveVideo } from "../../../queries/videos/detail";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import { load } from "exifreader";
import { getOrientationAdjustment } from "../../../utilities/helpers/cropper-helper";

const { TabPane } = Tabs;
const { Sider } = Layout;

export const ImageDetails = (props: {
  documentTemplateId: string;
  imageTemplate: ImageTemplate;
  viewIndex: number;
  imageIndex: number;
}) => {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();
  const { values, setFieldValue, submitForm, isSubmitting } = useFormikContext<DocumentSeries>();

  const image = values.views[props.viewIndex].images?.[props.imageIndex] as ImageType;

  const [polyState, setPolyState] = useState<Point[] | undefined>(undefined);
  const [rectState, setRectState] = useState<Cropper.Data | undefined>(undefined);
  const cropperRef = useRef<ReactCropperElement>(null);

  const [state, setState] = useState<{
    showAddSecurityFeatureModal: boolean;
    activeImageTabKey: string;
    isDragOver: boolean;
    copyInstructionsModalVisible: boolean;
  }>({
    showAddSecurityFeatureModal: false,
    activeImageTabKey: "securityFeatures",
    isDragOver: false,
    copyInstructionsModalVisible: false,
  });

  const { mutateAsync: saveFile, isLoading: isUploadingFile } = useUploadFile();
  const { mutateAsync: saveVideo, isLoading: isUploadingVideo } = useSaveVideo();
  const { mutateAsync: deleteVideo, isLoading: isDeletingVideo } = useDeleteVideo();
  const { data: file, isLoading: isLoadingFile } = useQueryFile(image.fileId);
  const [src, setSrc] = useState<string>("");

  const [orientation, setOrientation] = useState<number>(0);

  useEffect(() => {
    setSrc(!!file ? URL.createObjectURL(file) : "");

    (async () => {
      if (file) {
        const tags = load(await file.arrayBuffer(), { expanded: true });
        setOrientation(tags.exif?.Orientation?.value ?? 0);
      }
    })();
  }, [file]);

  useEffect(() => {
    const onDragEnter = () => {
      if (!state.isDragOver) {
        setState((prevState) => ({ ...prevState, isDragOver: true }));
      }
    };

    const onDragLeave = (event) => {
      if (event.screenX === 0 && event.screenY === 0) {
        setState((prevState) => ({ ...prevState, isDragOver: false }));
      }
    };

    window.addEventListener("dragenter", onDragEnter);
    window.addEventListener("dragleave", onDragLeave);
    window.addEventListener(
      "dragover",
      (e) => {
        e.preventDefault();
      },
      false,
    );
    window.addEventListener(
      "drop",
      (e) => {
        e.preventDefault();
      },
      false,
    );

    return () => {
      window.removeEventListener("dragenter", onDragEnter);
      window.removeEventListener("dragleave", onDragLeave);
      window.removeEventListener("dragover", onDragLeave);
      window.removeEventListener("drop", onDragLeave);
    };
  }, [state.isDragOver]);

  const onAddVideo = async (file: string | Blob | RcFile, index: number) => {
    const response = await saveFile({ file });

    const uploadVideo = await saveVideo({
      fileId: response.id,
      filename: response.fileName,
      imageId: image?.id,
      entityId: values.id,
    } as Video);

    setFieldValue(`views[${props.viewIndex}].images[${props.imageIndex}].videos[${index}]`, {
      id: uploadVideo.id,
      fileId: response.id,
      filename: response.fileName,
      imageId: image?.id,
      entityId: values.id,
    } as Video);
  };

  const onRemoveVideo = async (id: string, videos: Array<Video>) => {
    setFieldValue(`views[${props.viewIndex}].images[${props.imageIndex}].videos`, videos);
    await deleteVideo(id);
  };

  const onShowRegion = (
    shapeType: "RECTANGLE" | "POLYGON",
    crop?: Cropper.Data,
    points?: Point[],
  ) => {
    const generatedImage = new Image();
    generatedImage.src = src;
    generatedImage.crossOrigin = "anonymous";
    generatedImage.onload = () => {
      if (shapeType === "RECTANGLE") {
        const data = {
          height: generatedImage.height * (crop?.height ?? 1),
          width: generatedImage.width * (crop?.width ?? 1),
          x: generatedImage.width * (crop?.x ?? 1),
          y: generatedImage.height * (crop?.y ?? 1),
          rotate: getOrientationAdjustment(orientation),
          scaleX: 1,
          scaleY: 1,
        } as Cropper.Data;

        setRectState(data);
      }

      if (shapeType === "POLYGON") {
        const poly = points?.map((point) => ({
          x: generatedImage.width * point.x,
          y: generatedImage.height * point.y,
        }));

        setPolyState(poly);
      }
    };
  };

  const onClearRegion = () => {
    setPolyState(undefined);
    setRectState(undefined);
  };

  const uploadFile = async (file: string | Blob | RcFile, fileId: string) => {
    const fileResponse = await saveFile({ id: fileId, file });

    if (fileResponse.id !== fileId) {
      setFieldValue(
        `views[${props.viewIndex}].images[${props.imageIndex}].fileId`,
        fileResponse.id,
      );

      await submitForm();
    }
  };

  const [anotherSelection, setAnotherSelection] = useState<{
    securityFeatureSelection: SecurityFeatureSelection;
    selectedSecurityFeatureId: string;
    preGeneratedImagesCount?: number;
  }>();
  const handleSecurityFeatureAdd = async (
    securityFeatureSelection: SecurityFeatureSelection,
    selectedSecurityFeatureId: string,
    preGeneratedImagesCount?: number,
  ) => {
    const allDocumentSecurityFeatureSelections = _.flatMap(values.views, (view) =>
      _.flatMap(view.images, (image) => image.securityFeatureSelections),
    ) as SecurityFeatureSelection[];

    const sameSecurityFeatures = allDocumentSecurityFeatureSelections.filter(
      (securityFeatureSelection) =>
        securityFeatureSelection &&
        securityFeatureSelection.securityFeatureId === selectedSecurityFeatureId,
    );

    securityFeatureSelection.order =
      sameSecurityFeatures.length > 0
        ? _.maxBy(sameSecurityFeatures, (securityFeature) => securityFeature.order).order + 1
        : 0;

    if (preGeneratedImagesCount) {
      for (let i = 0; i < preGeneratedImagesCount; i++) {
        securityFeatureSelection.images = generateSecurityFeatureSelectionPlaceholder(
          securityFeatureSelection.images ?? [],
        );
      }
    }

    const selections =
      values.views[props.viewIndex].images?.[props.imageIndex].securityFeatureSelections?.slice(0);

    selections?.push(securityFeatureSelection);

    setFieldValue(
      `views[${props.viewIndex}].images[${props.imageIndex}].securityFeatureSelections`,
      selections,
    );

    await submitForm();
    setAnotherSelection({
      securityFeatureSelection: {
        ...securityFeatureSelection,
        images: undefined,
      },
      selectedSecurityFeatureId,
      preGeneratedImagesCount,
    });
  };

  return (
    <>
      <CmsPageLoader
        loading={
          isUploadingFile ||
          isDeletingVideo ||
          isUploadingVideo ||
          (isLoadingFile && image?.fileId !== undefined && image?.fileId !== null)
        }
        subTitle={t("common:loadingData")}
      >
        <Layout>
          <Sider
            width={"auto"}
            style={{
              overflow: "hidden",
              background: "#fff",
              paddingRight: "16px",
            }}
          >
            {canUpdate && (
              <Row gutter={[5, 5]}>
                <Col>
                  <CmsUploadButton
                    accept="image/*"
                    customRequest={(options) => {
                      uploadFile(options.file, image?.fileId as string);
                    }}
                  />
                </Col>
                <Col>
                  {image?.fileId && (
                    <CmsDeleteButton
                      onConfirm={(e) => {
                        e?.stopPropagation();
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].fileId`,
                          null,
                        );
                      }}
                    />
                  )}
                </Col>
                <Col>
                  <>
                    <CmsButton
                      buttonType="add"
                      style={{ marginBottom: "12px", marginRight: "5px" }}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          showAddSecurityFeatureModal: true,
                        }));
                      }}
                    >
                      {t("common:addSecurityFeature")}
                    </CmsButton>
                    <CmsButton
                      buttonType="add"
                      type="default"
                      disabled={!anotherSelection}
                      onClick={async () => {
                        if (anotherSelection) {
                          await handleSecurityFeatureAdd(
                            anotherSelection.securityFeatureSelection,
                            anotherSelection.selectedSecurityFeatureId,
                            anotherSelection.preGeneratedImagesCount,
                          );
                        }
                      }}
                      title={t("common:anotherOneDescription")}
                    >
                      {t("common:anotherOne")}
                    </CmsButton>
                  </>
                </Col>
              </Row>
            )}

            {!isUploadingFile && !isDeletingVideo && !isUploadingVideo && (
              <Row>
                <Affix offsetTop={15}>
                  <Dragger
                    className="drag-container"
                    accept={"image/*"}
                    multiple={false}
                    customRequest={(options) => {
                      setState((prevState) => ({
                        ...prevState,
                        isDragOver: false,
                      }));
                      uploadFile(options.file, image?.fileId as string);
                    }}
                  >
                    {image?.fileId ? (
                      <div style={{ width: "425px" }}>
                        {polyState !== undefined && (
                          <div style={{ maxWidth: "100%", maxHeight: "600px" }}>
                            <ReactLassoSelect
                              src={src}
                              value={polyState}
                              imageStyle={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </div>
                        )}
                        {rectState !== undefined && (
                          <ReactCropper
                            ref={cropperRef}
                            id="selection-area"
                            preview={".preview"}
                            src={src}
                            dragMode="none"
                            background={false}
                            autoCrop={!!rectState}
                            viewMode={2}
                            style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
                            data={rectState}
                            scalable={false}
                            zoomable={false}
                            draggable={false}
                            movable={false}
                            disabled
                          />
                        )}
                        {rectState === undefined && polyState === undefined && (
                          <img
                            src={src}
                            style={{ maxWidth: "100%", maxHeight: "600px" }}
                            alt="document-image"
                          />
                        )}
                      </div>
                    ) : (
                      <img src="/assets/images/keesing-logo.jpg" alt="Keesing logo" />
                    )}

                    {state.isDragOver && (
                      <div className="drag-overlay">
                        <div className="drag-icon-and-text">
                          <div className="drag-icon">
                            <UploadOutlined />
                          </div>
                          <div className="drag-text">Choose a file or drag it here</div>
                        </div>
                      </div>
                    )}
                  </Dragger>
                </Affix>
              </Row>
            )}

            {state.showAddSecurityFeatureModal && (
              <AddSecurityFeatureModal
                onAdd={async (
                  securityFeatureSelection,
                  selectedSecurityFeatureId,
                  preGeneratedImagesCount,
                ) => {
                  setState((prevState) => ({
                    ...prevState,
                    showAddSecurityFeatureModal: false,
                  }));

                  await handleSecurityFeatureAdd(
                    securityFeatureSelection,
                    selectedSecurityFeatureId,
                    preGeneratedImagesCount,
                  );
                }}
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showAddSecurityFeatureModal: false,
                  }))
                }
              />
            )}
          </Sider>
          <CmsContent>
            <CmsTabs
              activeKey={state.activeImageTabKey}
              onChange={(activeKey) => {
                setState((prevState) => ({
                  ...prevState,
                  activeImageTabKey: activeKey,
                }));
              }}
            >
              <TabPane tab={t("entities:securityFeatures")} key="securityFeatures">
                <SecurityFeatureList
                  image={image as ImageType}
                  securityFeatureSelections={image?.securityFeatureSelections ?? []}
                  onShowRegion={onShowRegion}
                  onClearRegion={onClearRegion}
                  onChange={async (securityFeatureSelections) => {
                    setFieldValue(
                      `views[${props.viewIndex}].images[${props.imageIndex}].securityFeatureSelections`,
                      securityFeatureSelections,
                    );

                    await submitForm();
                  }}
                  orientation={getOrientationAdjustment(orientation)}
                  src={src}
                />
              </TabPane>
              <TabPane tab={t("entities:attributes")} key="attributes">
                <CmsForm>
                  <CmsText
                    readOnly={!canUpdate}
                    key="sourceInfo"
                    label={t("properties:source")}
                    placeholder={t("properties:source")}
                    maxLength={50}
                    value={image?.sourceInfo}
                    onChange={(e) =>
                      setFieldValue(
                        `views[${props.viewIndex}].images[${props.imageIndex}].sourceInfo`,
                        e.target.value,
                      )
                    }
                  />
                  <CmsFormItem label={t("properties:dpi")}>
                    <InputNumber
                      readOnly={!canUpdate}
                      key="dpi"
                      placeholder={t("properties:dpi")}
                      value={image?.dpi}
                      onChange={(value) =>
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].dpi`,
                          value,
                        )
                      }
                    />
                  </CmsFormItem>
                  <CmsFormItem label={t("properties:exportable")}>
                    <Checkbox
                      disabled={!canUpdate}
                      key="exportable"
                      checked={image?.exportable ?? true}
                      onChange={(e) =>
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].exportable`,
                          e.target.checked,
                        )
                      }
                    />
                  </CmsFormItem>
                  <CmsFormItem label={t("properties:vertical")}>
                    <Checkbox
                      disabled={!canUpdate}
                      key="vertical"
                      checked={image?.vertical ?? true}
                      onChange={(e) =>
                        setFieldValue(
                          `views[${props.viewIndex}].images[${props.imageIndex}].vertical`,
                          e.target.checked,
                        )
                      }
                    />
                  </CmsFormItem>
                </CmsForm>
              </TabPane>
              {props.imageTemplate.fieldLinks.length > 0 && (
                <TabPane tab={t("entities:fields")} key="fields">
                  <FieldList
                    src={src}
                    orientation={getOrientationAdjustment(orientation)}
                    imageId={image?.id as string}
                    fieldLinks={props.imageTemplate.fieldLinks}
                    fieldValues={image?.fieldValues ?? []}
                    documentTemplateId={props.documentTemplateId}
                    viewTemplateId={values.views[props.viewIndex].viewTemplateId}
                    imageTemplateId={image?.imageTemplateId as string}
                    onShowRegion={onShowRegion}
                    onClearRegion={onClearRegion}
                    onChange={(fieldValues) => {
                      setFieldValue(
                        `views[${props.viewIndex}].images[${props.imageIndex}].fieldValues`,
                        fieldValues,
                      );
                    }}
                    isSubmitting={isSubmitting}
                    onSaveDocument={async () => await submitForm()}
                  />
                </TabPane>
              )}
              <TabPane tab={t("common:instructions")} key="instructions" disabled={!canUpdate}>
                <DocumentsImageInstruction
                  viewIndex={props.viewIndex}
                  imageIndex={props.imageIndex}
                />
              </TabPane>
              <TabPane tab={t("common:videos")} key="videos">
                <VideoManagement
                  videos={image?.videos ?? []}
                  onAdd={onAddVideo}
                  onRemove={onRemoveVideo}
                />
              </TabPane>
            </CmsTabs>
          </CmsContent>
        </Layout>
      </CmsPageLoader>
    </>
  );
};
