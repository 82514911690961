import { DocumentSeries } from "../../models/data/Document";
import api from "../../services/api";
import { CountResponse } from "../../services/api/types";
import {
  CONST_DOCUMENT_SERIES,
  CONST_DOCUMENT_TEMPLATES,
} from "../../utilities/constants/query-contants";
import { DocumentAttributeQuery, FilterRequest, IndexSearchQueryParams } from "./document-series-type";
import { useQuery } from "@tanstack/react-query";

export const getDocumentSeriesIndex = (
  payload: IndexSearchQueryParams,
): Promise<CountResponse<DocumentSeries[]>> => {
  return api.post({
    path: `${CONST_DOCUMENT_SERIES.plural}/QueryFilter`,
    body: { ...payload },
    isTotalCount: true,
  });
};

export const useQueryDocumentSeriesIndex = (payload: IndexSearchQueryParams) => {
  return useQuery<CountResponse<DocumentSeries[]>>(
    [`${CONST_DOCUMENT_SERIES.plural}-list-index`, { payload }],
    () => getDocumentSeriesIndex(payload),
  );
};

export const getDocumentSeriesByAttributeValues = (
  payload?: DocumentAttributeQuery,
): Promise<DocumentSeries[]> => {
  return api.post({
    path: `${CONST_DOCUMENT_SERIES.plural}/attribute`,
    body: payload,
  });
};

export const useQueryDocumentSeriesByAttributeValues = (payload: DocumentAttributeQuery) => {
  return useQuery<DocumentSeries[]>(
    [`${CONST_DOCUMENT_SERIES.plural}/attribute`, { payload }],
    () => getDocumentSeriesByAttributeValues(payload),
    { enabled: !!payload },
  );
};

export const getDocumentSeries = (payload?: FilterRequest): Promise<DocumentSeries[]> => {
  return api.get({
    path: `${CONST_DOCUMENT_SERIES.plural}`,
    query: payload
  });
};

export const useQueryDocumentSeries = (payload?: FilterRequest) => {
  return useQuery<DocumentSeries[]>(
    [`${CONST_DOCUMENT_SERIES.plural}/${CONST_DOCUMENT_TEMPLATES.singular}`, { payload }],
    () => getDocumentSeries(payload),
    { enabled: !!payload },
  );
};
