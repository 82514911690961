import { useQuery } from "@tanstack/react-query";
import { DocumentType } from "../../models/reference_lists/DocumentType";
import api from "../../services/api";
import { CONST_DOCUMENT_TYPES } from "../../utilities/constants/query-contants";

export const getDocumentTypes = (): Promise<DocumentType[]> => {
  return api.get({
    path: CONST_DOCUMENT_TYPES.plural,
  });
};

export const useQueryDocumentTypes = () => {
  return useQuery<DocumentType[]>([CONST_DOCUMENT_TYPES.plural], () => getDocumentTypes());
};
