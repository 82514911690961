import { Col, DatePicker, Layout, Row, Select } from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries, DocumentStatus } from "../../../models/data/Document";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import {
  CmsCreateButton,
  CmsRetractLeftButton,
  CmsExpandRightButton,
  CmsRemoveFiltersButton,
} from "../../common/ButtonComponents";
import { CmsSelect, CmsFilter, CmsFormItem } from "../../common/FormComponents";
import { CmsContent, CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { DocumentWizard } from "./DocumentWizard/DocumentWizard";
import { Attribute, AttributeDataType } from "../../../models/templates/Attribute";
import { AttributeFilterSchema } from "../../templates/attributes/AttributeFilterSchema";
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { DocumentTemplateEntity } from "../../../models/templates/DocumentTemplate";
import { getDocumentIndexListConfig } from "../../../utilities/configs/DocumentListConfig";
import { useDocumentFilter } from "../../../hooks/useDocumentFilter";
import { useNotification } from "../../../hooks/useNotification";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useQueryDocumentTemplate } from "../../../queries/document-template/detail";
import { useQueryAttributes } from "../../../queries/attributes/lists";
import { useDeleteDocumentSerie } from "../../../queries/document-series/detail";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";
import {
  constructDateRange,
  constructIndexQuery,
  formatIndexFilter,
  sanatizeGuid,
} from "../../../utilities/helpers/index-filtering-helper";
import { QueryStringParams } from "../../../queries/document-series/document-series-type";
import { useQuerySearch } from "../../../queries/search/detail";
import { sanatizeString } from "../../../utilities/configs/DocumentStatisticsConfig";

export const DocumentsList = () => {
  const { documentTemplateId } = useParams<DocumentTemplateEntity>();
  const context = useCmsContext();
  const { canDelete, canCreate, canUpdate, canView } = useAuthorization("document");
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();
  const history = useHistory();
  const {
    changeFilterPanel,
    saveFilters,
    restoreFilters,
    resetFilters,
    disableBefore,
    disabledAfter,
  } = useDocumentFilter();

  const [state, setState] = useState<{
    expandedTypes: string[];
    showNewDocumentWizard: boolean;
    filterPanelShow: boolean;
  }>({
    expandedTypes: [],
    showNewDocumentWizard: false,
    filterPanelShow: context?.documentSeriesFilterPanel ?? false,
  });
  const [queryFilter, setQueryFilter] = useState<QueryStringParams>();
  const [modifiedRangeFilter, setModifiedRangeFilter] = useState<{
    start?: string;
    end?: string;
  }>();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const restored = restoreFilters();
    const dateRestored = restoreFilters("dateRangeFilter");
    if (
      (!!restored || !!dateRestored) &&
      restored?.documentTemplateId === sanatizeGuid(documentTemplateId)
    ) {
      setQueryFilter(restored);
      setModifiedRangeFilter(dateRestored);
    } else {
      resetFilters();
      resetFilters("dateRangeFilter");
      setQueryFilter({ documentTemplateId: sanatizeGuid(documentTemplateId) });
    }
  }, [documentTemplateId]);

  const { data: documentTemplate, isLoading: isLoadingDocumentTemplate } =
    useQueryDocumentTemplate(documentTemplateId);
  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: attributes, isLoading: isLoadingAttributes } = useQueryAttributes({
    documentTemplateId,
    filterable: true,
  });
  const { mutateAsync: deleteDocument, isLoading: isDeletingDocument } = useDeleteDocumentSerie();
  const { data: documents, isLoading: isLoadingDocuments } = useQuerySearch(
    constructIndexQuery(queryFilter ?? {}),
  );

  const isLoading =
    isLoadingDocumentTypes || isLoadingDocuments || isLoadingDocumentTemplate || isDeletingDocument;

  const [filteredDocuments, setFilteredDocuments] = useState<DocumentSeries[]>(documents ?? []);

  useEffect(() => {
    setFilteredDocuments(documents ?? []);
  }, [documents]);

  useEffect(() => {
    if (!!queryFilter) {
      saveFilters(queryFilter);
    }

    if (!!modifiedRangeFilter) {
      saveFilters(modifiedRangeFilter, "dateRangeFilter");
    }
  }, [queryFilter]);

  const ChangeIndexFilter = (name: string, value?: any) => {
    if (value !== null && value !== undefined) {
      return {
        ...queryFilter,
        [name]: value,
      };
    }

    if (queryFilter) {
      delete queryFilter[name];
    }

    return {
      ...queryFilter,
    };
  };

  const search = (searchText: string) => {
    if (documents) {
      const search = searchText.toLowerCase().replace(" ", "_");
      const docs = documents.filter((document) => {
        const name = document.code && document.code.toLowerCase();
        return name && name.includes(search);
      });
      setSearchText(searchText);
      setFilteredDocuments(docs);
    }
  };

  useEffect(() => {
    context?.setDuplicateErrorMessage(t("errors:documentLinked"));
  }, [t]);

  const filterActive =
    (queryFilter?.attributes && Object.keys(queryFilter.attributes).length > 0) ||
    (queryFilter?.query &&
      Object.keys(queryFilter.query).filter((item) => item !== "documentTemplateId").length > 0);

  return (
    <React.Fragment>
      <CmsPageHeader
        title="Document series"
        extra={[
          filterActive && (
            <CmsRemoveFiltersButton
              key="resetFilter"
              onClick={() => {
                resetFilters();
                setQueryFilter({ query: { documentTemplateId } });
              }}
            />
          ),
          !context?.documentSeriesFilterPanel ? (
            <CmsExpandRightButton key="expand" onClick={() => changeFilterPanel(true)} />
          ) : (
            <CmsRetractLeftButton key="retract" onClick={() => changeFilterPanel(false)} />
          ),
          <CmsSearch key="search" value={searchText} onChange={search} />,
          canCreate && (
            <CmsCreateButton
              key="create"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  showNewDocumentWizard: true,
                }))
              }
            />
          ),
        ]}
      />

      <Layout>
        <CmsContent>
          <Row>
            {context?.documentSeriesFilterPanel && (
              <Col flex={1}>
                <h1
                  style={{
                    textAlign: "right",
                    paddingRight: 20,
                    paddingTop: 15,
                    paddingBottom: 15,
                  }}
                >
                  Filters <FilterOutlined />
                </h1>
                <CmsFilter style={{ paddingRight: 20 }}>
                  <CmsSelect
                    id="documentTypeId"
                    label={t("properties:type")}
                    placeholder={t("properties:type")}
                    value={queryFilter?.documentTypeId}
                    onChange={(documentTypeId) =>
                      setQueryFilter(
                        ChangeIndexFilter(
                          "documentTypeId",
                          !!documentTypeId ? sanatizeGuid(documentTypeId) : documentTypeId,
                        ),
                      )
                    }
                  >
                    {documentTypes?.map((item, index) => (
                      <Select.Option key={index} value={sanatizeGuid(item.id as string)}>
                        {getLocalizedValue(item.caption) || item.name}
                      </Select.Option>
                    ))}
                  </CmsSelect>

                  <CmsSelect
                    id="documentStatus"
                    label={t("properties:status")}
                    placeholder={t("properties:status")}
                    value={queryFilter?.status}
                    onChange={(e) => setQueryFilter(ChangeIndexFilter("status", e))}
                  >
                    {Object.values(DocumentStatus).map((item) => (
                      <Select.Option value={sanatizeString(item.toString(), "_")}>
                        {t(`properties:documentStatus${item}`)}
                      </Select.Option>
                    ))}
                  </CmsSelect>
                  <CmsFormItem label={t("common:from")}>
                    <DatePicker
                      value={modifiedRangeFilter?.start ? dayjs(modifiedRangeFilter?.start) : null}
                      placeholder={t("properties:date")}
                      onChange={(_, datestring) => {
                        setModifiedRangeFilter((prevState) => ({
                          ...prevState,
                          start: !!datestring ? (datestring as string) : undefined,
                        }));

                        setQueryFilter(
                          ChangeIndexFilter(
                            "modified",
                            datestring || modifiedRangeFilter?.end
                              ? constructDateRange(datestring as string, modifiedRangeFilter?.end)
                              : undefined,
                          ),
                        );
                      }}
                      disabledDate={(current) => disabledAfter(current, modifiedRangeFilter?.start)}
                    />
                  </CmsFormItem>
                  <CmsFormItem label={t("common:until")}>
                    <DatePicker
                      value={modifiedRangeFilter?.end ? dayjs(modifiedRangeFilter?.end) : null}
                      placeholder={t("properties:date")}
                      onChange={(_, datestring) => {
                        setModifiedRangeFilter((prevState) => ({
                          ...prevState,
                          end: !!datestring ? (datestring as string) : undefined,
                        }));

                        setQueryFilter(
                          ChangeIndexFilter(
                            "modified",
                            modifiedRangeFilter?.start || datestring
                              ? constructDateRange(modifiedRangeFilter?.start, datestring as string)
                              : undefined,
                          ),
                        );
                      }}
                      disabledDate={(current) => disableBefore(current, modifiedRangeFilter?.end)}
                    />
                  </CmsFormItem>
                  {!isLoadingAttributes && !!attributes ? (
                    attributes.map((attr: Attribute) => {
                      return (
                        <AttributeFilterSchema
                          key={attr.name}
                          attr={attr}
                          dataType={attr.dataType as AttributeDataType}
                          defaultValue={queryFilter?.[formatIndexFilter(attr)]}
                          updateDefaultValue={(_, value) => {
                            if (!!attr.name) {
                              setQueryFilter(
                                ChangeIndexFilter(
                                  formatIndexFilter(attr),
                                  !!value ? sanatizeGuid(value) : value,
                                ),
                              );
                            }
                          }}
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        left: "50%",
                        marginTop: 100,
                      }}
                    >
                      <LoadingOutlined style={{ fontSize: 40 }} />
                    </div>
                  )}
                </CmsFilter>
              </Col>
            )}

            <Col flex={2}>
              <CmsTable
                loading={isLoading}
                dataSource={filteredDocuments}
                rowKey="id"
                columns={getDocumentIndexListConfig(documentTemplate?.name as string, t)}
                onEdit={
                  canUpdate || canView
                    ? (id) => history.push(`/document-series/${documentTemplateId}/${id}`)
                    : undefined
                }
                onDelete={canDelete ? async (id) => await deleteDocument(id) : undefined}
              />
            </Col>
          </Row>
        </CmsContent>
      </Layout>

      {state.showNewDocumentWizard && (
        <DocumentWizard
          onClose={async (newDocument) => {
            if (newDocument && newDocument.documentTemplateId === documentTemplateId) {
              notifySuccess(
                t("common:successSave", {
                  entity: t("common:document").toLocaleLowerCase(),
                }),
              );
            }
            setState((prevState) => ({
              ...prevState,
              showNewDocumentWizard: false,
            }));

            if (newDocument?.id) {
              window.location.replace(
                `/document-series/${newDocument.documentTemplateId}/${newDocument?.id}`,
              );
            }
          }}
        />
      )}
    </React.Fragment>
  );
};
