import * as _ from "lodash";
import { Group } from "../models/templates/Group";

export const convertGroupsForTreeSelect = (groups: Group[]) => {
  const convertGroup = (group: Group) => {
    return {
      id: group.id,
      key: group.id,
      parentId: group.parentId,
      title: group.name,
      value: group.id,
      children: group.children?.map((childGroup) => convertGroup(childGroup)) || [],
    };
  };

  return _.map(groups, (group) => {
    return convertGroup(group);
  });
};
