export const defaultOrderedLanguages = [
  { order: 1, isoCode: "nl" },
  { order: 2, isoCode: "en" },
  { order: 3, isoCode: "de" },
  { order: 4, isoCode: "fr" },
  { order: 5, isoCode: "it" },
  { order: 6, isoCode: "es" },
  { order: 7, isoCode: "ru" },
  { order: 8, isoCode: "zh" },
];
