import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryCurrency, useSaveCurrency } from "../../../queries/currencies/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import { Currency } from "../../../models/reference_lists/Currency";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import ControlledNumberInput from "../../form/ControlledNumberInput";

export const CurrencyDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("currency");
  const { push } = useHistory();
  const { id: currencyId } = useParams<Entity>();
  const context = useCmsContext();
  const { maxLength, required, numbersOnly } = useRules();

  const {
    data: currency,
    isLoading: isLoadingCurrency,
    refetch: refetchCurrency,
  } = useQueryCurrency(currencyId);
  const { mutateAsync: saveCurrency, isLoading: isSavingCurrency } = useSaveCurrency();

  useEffect(() => {
    if (currency) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateCurrency", {
          name: currency.name,
          isoCode: currency.isoCode,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "currency",
          name: currency.name ?? t("common:new"),
        },
      ]);
    }
  }, [currency, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<Currency>({
    mode: "onChange",
    values: currency,
  });

  const isLoadingAny = (isLoadingCurrency && !!currencyId) || isSavingCurrency;
  const noDataAny = !currency && !!currencyId;

  const submit = async (currency: Currency) => {
    await saveCurrency(currency);
    !currency.id ? push(`/currencies`) : await refetchCurrency();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <CmsForm>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:currency")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/currencies`)} />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <ControlledInput
        control={control}
        name={"name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:name")}
        rules={{
          ...maxLength(50, t("properties:name")),
          ...required(t("properties:name")),
        }}
      />
      <ControlledInput
        control={control}
        name={"isoCode"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:isoCode3")}
        rules={{
          ...maxLength(3, t("properties:isoCode3")),
          ...required(t("properties:isoCode3")),
        }}
      />
      <ControlledInput
        control={control}
        name={"isoNumber"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:isoNumber3")}
        rules={{
          ...maxLength(3, t("properties:isoNumber3")),
          ...required(t("properties:isoNumber3")),
          ...numbersOnly(t("properties:isoNumber3")),
        }}
      />
      <ControlledNumberInput
        control={control}
        name={"exponent"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:exponent")}
      />
      <ControlledInput
        control={control}
        name={"subunit"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:subunit")}
        rules={{
          ...maxLength(50, t("properties:subunit")),
        }}
      />
      <ControlledInput
        control={control}
        name={"symbol"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:symbol")}
        rules={{
          ...maxLength(50, t("properties:symbol")),
        }}
      />
    </CmsForm>
  );
};
