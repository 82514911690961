export class FieldLink {
  public id?: string;
  public fieldId?: string;
  public fieldName?: string;
  public imageTemplateId?: string;
  public isRequired?: boolean;
  public order?: number;
  public exportable: boolean = true;

  public withImageTemplate(imageTemplateId: string) {
    this.imageTemplateId = imageTemplateId;
    return this;
  }

  public withField(fieldId: string) {
    this.fieldId = fieldId;
    return this;
  }
}
