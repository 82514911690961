import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { CONST_ATTRIBUTES_GROUPS, CONST_GROUPS } from "../../utilities/constants/query-contants";
import { Group } from "../../models/templates/Group";
import { GroupType } from "./group-types";

export const getGroups = (group: GroupType): Promise<Group[]> => {
  return api.get({
    path: `${group}${CONST_GROUPS.plural}`,
  });
};

export const useQueryGroups = (group: GroupType) => {
  return useQuery<Group[]>([CONST_ATTRIBUTES_GROUPS.plural, { group }], () => getGroups(group));
};
