import { PlusOutlined } from "@ant-design/icons";
import { Button, Select, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Organization } from "../../../models/reference_lists/Organization";
import { Product } from "../../../models/reference_lists/Product";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem, CmsSelect } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { RowButtonSet } from "../../common/RowButtonSet";
import { ProductDetailsModal } from "./ProductDetailsModal";
import { useQueryCountries } from "../../../queries/countries/lists";
import { useQueryOrganization, useSaveOrganization } from "../../../queries/organizations/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import useRules from "../../../hooks/useRules";
import { useForm } from "react-hook-form";
import ControlledCheckboxGroup from "../../form/ControlledCheckboxGroup";
import ControlledInput from "../../form/ControlledInput";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";

export const OrganizationDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("organization");
  const { push } = useHistory();
  const { id: organizationId } = useParams<Entity>();
  const context = useCmsContext();
  const { required, maxLength, isValidPhoneNumber, isValidEmail, isValidUrl, isValidName } =
    useRules();

  const [state, setState] = React.useState<{
    selectedProduct: Product | null;
    selectedProductIndex: number;
    modalVisible: boolean;
  }>({
    selectedProduct: null,
    selectedProductIndex: -1,
    modalVisible: false,
  });

  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();
  const {
    data: organization,
    isLoading: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useQueryOrganization(organizationId);
  const { mutateAsync: saveOrganization, isLoading: isSavingOrganization } = useSaveOrganization();

  React.useEffect(() => {
    if (organization) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateOrganization", {
          name: organization.name,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "organization",
          name: organization.name ?? t("common:new"),
        },
      ]);
    }
  }, [organization, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting, errors },
    setValue,
    getValues,
  } = useForm<Organization>({
    mode: "onChange",
    values: organization,
  });

  const isLoadingAny =
    (isLoadingOrganization && !!organizationId) || isLoadingCountries || isSavingOrganization;
  const noDataAny = (!organization && !!organizationId) || !countries;

  const submit = async (organization: Organization) => {
    await saveOrganization(organization);
    !organization.id ? push(`/organizations`) : await refetchOrganization();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <CmsForm>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:organization")}
        extra={[
          <CmsBackButton
            key="back"
            disabled={isLoadingAny}
            onClick={() => push(`/organizations`)}
          />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <ControlledCheckboxGroup
        name="type"
        control={control}
        isDisabled={!canUpdate || isLoadingAny}
        label={t("properties:type")}
        rules={{
          ...required(t("properties:type")),
        }}
        options={[
          {
            label: t("properties:organizationTypeProducer"),
            value: "PRODUCER",
          },
          {
            label: t("properties:organizationTypeIssuer"),
            value: "ISSUER",
          },
          {
            label: t("properties:organizationTypeReference"),
            value: "REFERENCE",
          },
        ]}
      />
      <ControlledCheckboxGroup
        name="productType"
        control={control}
        isDisabled={!canUpdate || isLoadingAny}
        label={t("properties:productType")}
        rules={{
          ...required(t("properties:productType")),
        }}
        options={[
          {
            label: t("properties:productTypeDocument"),
            value: "DOCUMENT",
          },
          {
            label: t("properties:productTypeBanknote"),
            value: "BANKNOTE",
          },
        ]}
      />
      <ControlledInput
        control={control}
        name={"name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:name")}
        rules={{
          ...required(t("properties:name")),
          ...maxLength(150, t("properties:name")),
          ...isValidName(t("properties:name")),
        }}
      />

      {/* TODO: change this to controller variant when ant 6 is installed */}
      <CmsSelect
        label={t("properties:country")}
        placeholder={t("properties:country")}
        disabled={isLoadingAny}
        readOnly={!canUpdate}
        loading={isLoadingCountries}
        error={errors?.countryId}
        value={getValues().countryId}
        onChange={(value) =>
          setValue("countryId", value, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          })
        }
      >
        {countries.map((country) => (
          <Select.Option key={country.id} value={country.id}>
            {getLocalizedValue(country.name)}
          </Select.Option>
        ))}
      </CmsSelect>

      <ControlledInput
        control={control}
        name={"contact.name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:contactPerson")}
        rules={{
          ...maxLength(150, t("properties:contactPerson")),
        }}
      />
      <ControlledInput
        control={control}
        name={"contact.telephoneWork"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:phoneNumberWork")}
        rules={{
          ...maxLength(14, t("properties:phoneNumberWork")),
          ...isValidPhoneNumber(),
        }}
      />
      <ControlledInput
        control={control}
        name={"contact.telephonePrivate"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:phoneNumberPrivate")}
        rules={{
          ...maxLength(14, t("properties:phoneNumberPrivate")),
          ...isValidPhoneNumber(),
        }}
      />
      <ControlledInput
        control={control}
        name={"contact.emailAddress"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:email")}
        rules={{
          ...maxLength(200, t("properties:email")),
          ...isValidEmail(),
        }}
      />
      <ControlledInput
        control={control}
        name={"webAddress"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:webAddress")}
        rules={{
          ...maxLength(200, t("properties:webAddress")),
          ...isValidUrl(),
        }}
      />
      <ControlledInput
        control={control}
        name={"address.addressLine1"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:addressLine1")}
        rules={{
          ...maxLength(250, t("properties:addressLine1")),
        }}
      />
      <ControlledInput
        control={control}
        name={"address.addressLine2"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:addressLine2")}
        rules={{
          ...maxLength(250, t("properties:addressLine2")),
        }}
      />
      <ControlledInput
        control={control}
        name={"address.city"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:city")}
        rules={{
          ...maxLength(250, t("properties:city")),
        }}
      />
      <ControlledInput
        control={control}
        name={"address.postalCode"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:postalCode")}
        rules={{
          ...maxLength(10, t("properties:postalCode")),
        }}
      />
      <ControlledInput
        control={control}
        name={"remarks"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:remarks")}
        rules={{
          ...maxLength(100, t("properties:remarks")),
        }}
      />
      <CmsFormItem label={t("entities:products")}>
        <Table
          bordered
          size={"small"}
          style={{ marginBottom: "16px" }}
          columns={[
            { title: t("properties:name"), dataIndex: "name" },
            {
              title: t("properties:trademark"),
              dataIndex: "hasTrademark",
              render: (hasTrademark) => (hasTrademark ? t("common:yes") : t("common:no")),
            },
            {
              title: t("properties:registered"),
              dataIndex: "isRegistered",
              render: (isRegistered) => (isRegistered ? t("common:yes") : t("common:no")),
            },
            ...(!!canUpdate
              ? [
                  {
                    title: (
                      <Button
                        disabled={isLoadingAny}
                        type="primary"
                        shape="circle"
                        icon={<PlusOutlined />}
                        style={{ margin: "0px 2px 0px 2px" }}
                        size="small"
                        onClick={() => {
                          const newProduct = new Product();
                          newProduct.organizationId = organization?.id;
                          setState((prevState) => ({
                            ...prevState,
                            selectedProduct: newProduct,
                            selectedProductIndex: -1,
                            modalVisible: true,
                          }));
                        }}
                      />
                    ),
                    dataIndex: "actions",
                    align: "right" as any,
                    render: (_, record: any, index) => (
                      <RowButtonSet
                        onEdit={() =>
                          setState((prevState) => ({
                            ...prevState,
                            selectedProduct: record,
                            modalVisible: true,
                            selectedProductIndex: index,
                          }))
                        }
                        onDelete={() => {
                          const productsCopy = getValues().products.slice(0);
                          productsCopy.splice(index, 1);
                          setValue("products", productsCopy, {
                            shouldTouch: true,
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                          setState((prevState) => ({
                            ...prevState,
                            selectedProduct: null,
                            selectedProductIndex: -1,
                          }));
                        }}
                      />
                    ),
                  },
                ]
              : []),
          ]}
          dataSource={getValues().products}
          rowKey={(row, index) => row.id || (index as number)}
          pagination={false}
        />
      </CmsFormItem>
      <ProductDetailsModal
        product={state.selectedProduct as Product}
        visible={state.modalVisible}
        onSave={(product) => {
          const productsCopy = getValues().products.slice(0);

          if (state.selectedProductIndex >= 0) {
            productsCopy[state.selectedProductIndex] = product;
          } else {
            productsCopy.push(product);
          }

          setValue("products", productsCopy, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          });

          setState((prevState) => ({
            ...prevState,
            selectedProduct: null,
            selectedProductIndex: -1,
            modalVisible: false,
          }));
        }}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            selectedProduct: null,
            selectedProductIndex: -1,
            modalVisible: false,
          }));
        }}
      />
    </CmsForm>
  );
};
