import {
  BarsOutlined,
  DatabaseOutlined,
  HomeOutlined,
  SettingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useAuthorization } from "../hooks/useAuthorization";
import { DocumentTemplate } from "../models/templates/DocumentTemplate";
import { getLocalizedValue } from "../utilities/MultilingualHelper";
import { CmsBreadcrumb } from "./CmsBreadcrumb";
import { SearchBox } from "./common/SearchBox";
import { Settings } from "./Settings";
import { useKeycloak } from "@react-keycloak/web";
import { useQueryDocumentTemplates } from "../queries/document-template/lists";

const { Header, Content, Footer, Sider } = Layout;

export const CmsLayout: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const location = useLocation();

  const [state, setState] = useState<{
    collapsed: boolean;
    settingsModalVisible: boolean;
  }>({
    collapsed: false,
    settingsModalVisible: false,
  });

  const { data: documentTemplates } = useQueryDocumentTemplates();

  const { canView: showAttributes } = useAuthorization("attribute");
  const { canView: showCountries } = useAuthorization("country");
  const { canView: showCurrencies } = useAuthorization("currency");
  const { canView: showDocumentTemplates } = useAuthorization("documentTemplate");
  const { canView: showDocumentTypes } = useAuthorization("documentType");
  const { canView: showDocuments } = useAuthorization("document");
  const { canView: showFields } = useAuthorization("field");
  const { canView: showLanguages } = useAuthorization("language");
  const { canView: showMaterialTypes } = useAuthorization("materialType");
  const { canView: showOrganizations } = useAuthorization("organization");
  const { canView: showRegions } = useAuthorization("region");
  const { canView: showSecurityFeatures } = useAuthorization("securityFeature");
  const { canView: showAdministration } = useAuthorization("administration");

  const showReferenceLists =
    showCountries ||
    showCurrencies ||
    showDocumentTypes ||
    showLanguages ||
    showMaterialTypes ||
    showOrganizations ||
    showRegions;
  const showTemplates =
    showAttributes || showDocumentTemplates || showFields || showSecurityFeatures;

  const menuStructure = {
    documentSeries: [{ name: "", path: "/document-series", available: true }],
    templates: [
      {
        name: t("entities:attributes"),
        path: "/attributes",
        available: showAttributes,
      },
      {
        name: t("entities:documentTemplates"),
        path: "/document-templates",
        available: showDocumentTemplates,
      },
      { name: t("entities:fields"), path: "/fields", available: showFields },
      {
        name: t("entities:securityFeatures"),
        path: "/security-features",
        available: showSecurityFeatures,
      },
    ],
    referenceLists: [
      {
        name: t("entities:countries"),
        path: "/countries",
        available: showCountries,
      },
      {
        name: t("entities:currencies"),
        path: "/currencies",
        available: showCurrencies,
      },
      {
        name: t("entities:documentTypes"),
        path: "/document-types",
        available: showDocumentTypes,
      },
      {
        name: t("entities:languages"),
        path: "/languages",
        available: showLanguages,
      },
      {
        name: t("entities:materialTypes"),
        path: "/material-types",
        available: showMaterialTypes,
      },
      {
        name: t("entities:organizations"),
        path: "/organizations",
        available: showOrganizations,
      },
      { name: t("entities:regions"), path: "/regions", available: showRegions },
    ],
    administration: [
      {
        name: t("common:translations"),
        path: "/translations",
        available: true,
      },
      { name: t("common:imageUpload"), path: "/image-upload", available: true },
      {
        name: t("common:magazineExport"),
        path: "/magazine-export",
        available: true,
      },
      {
        name: t("common:documentStatistics"),
        path: "/document-statistics",
        available: true,
      },
      {
        name: t("common:changeTracker"),
        path: "/changes-tracker",
        available: true,
      },
    ],
  } as Record<string, { name: string; path: string; available: boolean }[]>;

  const getSubMenuKey = (searchString: string): string => {
    return (
      Object.keys(menuStructure).find((key) =>
        menuStructure[key].find((item) => searchString.includes(item.path)),
      ) ?? ""
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Sider
          collapsible
          collapsed={state.collapsed}
          onCollapse={(e) => setState((prevState) => ({ ...prevState, collapsed: e }))}
          style={{
            overflow: "auto",
            overflowX: "hidden",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          <div className="logo">
            {state.collapsed ? (
              <img src="/assets/images/keesing-icon-transparant-small.png" alt="Keesing" />
            ) : (
              <img src="/assets/images/keesing-logo-transparant-small.png" alt="Keesing" />
            )}
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={["home"]}
            mode="inline"
            style={{ marginBottom: "48px" }}
            selectedKeys={[`${location.pathname}`]}
            autoFocus
            forceSubMenuRender
            defaultOpenKeys={[getSubMenuKey(location.pathname)]}
          >
            <Menu.Item key="/" icon={<HomeOutlined />}>
              <Link to="/">{t("common:home")}</Link>
            </Menu.Item>

            {showDocuments && documentTemplates && (
              <Menu.SubMenu
                key="documentSeries"
                icon={<DatabaseOutlined />}
                title={t("entities:documentSeries")}
              >
                {documentTemplates.map((documentTemplate: DocumentTemplate) => (
                  <Menu.Item key={`/document-series/${documentTemplate.id}`}>
                    <Link to={`/document-series/${documentTemplate.id}`}>
                      {getLocalizedValue(documentTemplate.caption)}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            )}
            {showTemplates && (
              <Menu.SubMenu
                key="templates"
                icon={<SolutionOutlined />}
                title={t("entities:templates")}
              >
                {menuStructure["templates"].map(
                  (item) =>
                    item.available && (
                      <Menu.Item key={item.path}>
                        <Link to={item.path}>{item.name}</Link>
                      </Menu.Item>
                    ),
                )}
              </Menu.SubMenu>
            )}
            {showReferenceLists && (
              <Menu.SubMenu
                key="referenceLists"
                icon={<BarsOutlined />}
                title={t("entities:referenceLists")}
              >
                {menuStructure["referenceLists"].map(
                  (item) =>
                    item.available && (
                      <Menu.Item key={item.path}>
                        <Link to={item.path}>{item.name}</Link>
                      </Menu.Item>
                    ),
                )}
              </Menu.SubMenu>
            )}
            {showAdministration && (
              <Menu.SubMenu
                key="administration"
                icon={<SettingOutlined />}
                title={t("common:administration")}
              >
                {menuStructure["administration"].map(
                  (item) =>
                    item.available && (
                      <Menu.Item key={item.path}>
                        <Link to={item.path}>{item.name}</Link>
                      </Menu.Item>
                    ),
                )}
              </Menu.SubMenu>
            )}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: state.collapsed ? 80 : 200, minHeight: "100vh" }}>
          <Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#fff",
              padding: "0px 16px 0px 16px",
            }}
          >
            <SearchBox />
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        settingsModalVisible: true,
                      }))
                    }
                  >
                    {t("common:settings")}
                  </Menu.Item>
                  <Menu.Item onClick={() => keycloak.logout()}>{t("common:logout")}</Menu.Item>
                </Menu>
              }
            >
              <SettingOutlined style={{ fontSize: "24px" }} />
            </Dropdown>
          </Header>
          <Content style={{ margin: "16px", overflow: "initial" }}>
            <CmsBreadcrumb />
            <div style={{ padding: 24, background: "#fff" }}>{props.children}</div>
          </Content>
          {state.settingsModalVisible ? (
            <Settings
              onClose={() =>
                setState((prevState) => ({
                  ...prevState,
                  settingsModalVisible: false,
                }))
              }
            />
          ) : null}
          <Footer style={{ textAlign: "center" }}>
            Keesing Technologies CMS ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </React.Fragment>
  );
};
