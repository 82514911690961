import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentType } from "../../../models/reference_lists/DocumentType";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { CmsPageHeader, CmsSearch, CmsTable } from "../../common/PageComponents";
import { useQueryDocumentTypes } from "../../../queries/document-types/lists";
import { useDeleteDocumentType } from "../../../queries/document-types/detail";
import { useCmsContext } from "../../../context/app/CmsContext";

export const DocumentTypeList = () => {
  const { t } = useTranslation();
  const { canCreate, canDelete, canUpdate, canView } = useAuthorization("documentType");
  const { push } = useHistory();
  const context = useCmsContext();
  const {
    data: documentTypes,
    isLoading: isLoadingDocumentTypes,
    refetch: refetchDocumentTypes,
  } = useQueryDocumentTypes();
  const { mutateAsync: deleteDocumentType, isLoading: isLoadingDeleteDocumentType } =
    useDeleteDocumentType();

  const [documentTypesFilter, setDocumentTypesFilter] = useState<DocumentType[]>(
    documentTypes ?? [],
  );

  useEffect(() => {
    if (!!documentTypes) {
      setDocumentTypesFilter(documentTypes);
    }
  }, [documentTypes]);

  useEffect(() => {
    if (documentTypesFilter) {
      context?.setDuplicateErrorMessage(t("errors:documentTypeLinked"));
    }
  }, [documentTypesFilter, t]);

  const search = (searchText: any) => {
    if (documentTypes) {
      const search = searchText.toLowerCase();
      const filteredDocumentTypes = documentTypes.filter((documentType) => {
        const name = documentType.name && documentType.name.toLowerCase();
        const code = documentType.code && documentType.code.toLowerCase();
        return (name && name.includes(search)) || (code && code.includes(search));
      });

      setDocumentTypesFilter(filteredDocumentTypes);
    }
  };

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:documentTypes")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate ? (
            <CmsCreateButton key="create" onClick={() => push(`/document-type`)} />
          ) : null,
        ]}
      />
      <CmsTable
        loading={isLoadingDeleteDocumentType || isLoadingDocumentTypes}
        dataSource={documentTypesFilter}
        rowKey="id"
        columns={[
          { title: t("properties:name"), width: "30%", dataIndex: "name" },
          { title: t("properties:code"), width: "20%", dataIndex: "code" },
        ]}
        onEdit={canUpdate || canView ? (id) => push(`/document-type/${id}`) : undefined}
        onDelete={
          canDelete
            ? async (id) => {
                await deleteDocumentType(id);
                await refetchDocumentTypes();
              }
            : undefined
        }
      />
    </React.Fragment>
  );
};
