import React from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Language } from "../../../models/reference_lists/Language";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryLanguage, useSaveLanguage } from "../../../queries/languages/detail";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import ControlledInput from "../../form/ControlledInput";
import useRules from "../../../hooks/useRules";
import ControlledCheckbox from "../../form/ControlledCheckbox";

export const LanguageDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("language");
  const { push } = useHistory();
  const { id: languageId } = useParams<Entity>();
  const context = useCmsContext();
  const { maxLength, required } = useRules();

  const {
    data: language,
    isLoading: isLoadingLanguage,
    refetch: refetchLanguage,
  } = useQueryLanguage(languageId);
  const { mutateAsync: saveLanguage, isLoading: isSavingLanguage } = useSaveLanguage();

  React.useEffect(() => {
    if (language) {
      context?.setDuplicateErrorMessage(
        t("errors:duplicateLanguage", {
          isoCode: language.isoCode,
        }),
      );

      context?.setBreadcrumbItems([
        {
          key: "language",
          name: language.name ?? t("common:new"),
        },
      ]);
    }
  }, [language, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
  } = useForm<Language>({
    mode: "onChange",
    values: language,
  });

  const isLoadingAny = (isLoadingLanguage && !!languageId) || isSavingLanguage;
  const noDataAny = !language && !!languageId;

  const submit = async (language: Language) => {
    await saveLanguage(language);
    !language.id ? push(`/languages`) : await refetchLanguage();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <CmsForm>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:language")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/languages`)} />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <ControlledInput
        control={control}
        name={"name"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:name")}
        rules={{
          ...maxLength(100, t("properties:name")),
          ...required(t("properties:name")),
        }}
      />
      <ControlledInput
        control={control}
        name={"isoCode"}
        isLoading={isLoadingAny}
        canUpdate={canUpdate}
        label={t("properties:isoCode")}
        rules={{
          ...maxLength(2, t("properties:isoCode")),
          ...required(t("properties:isoCode")),
        }}
      />
      <ControlledCheckbox
        control={control}
        name={"isRightToLeft"}
        isDisabled={!canUpdate || isLoadingAny}
        label={t("properties:rightToLeft")}
      />
    </CmsForm>
  );
};
