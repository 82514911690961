import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Config from "./Config";
import translations_en from "./locales/en.json";
import translations_nl from "./locales/nl.json";

i18n.use(initReactI18next).init({
  lng: Config.language,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

i18n.addResourceBundle("en", "common", translations_en.common);
i18n.addResourceBundle("en", "entities", translations_en.entities);
i18n.addResourceBundle("en", "errors", translations_en.errors);
i18n.addResourceBundle("en", "properties", translations_en.properties);
i18n.addResourceBundle("en", "notifications", translations_en.notifications);
i18n.addResourceBundle("en", "texts", translations_en.texts);
i18n.addResourceBundle("en", "safeEditMode", translations_en.safeEditMode);
i18n.addResourceBundle("en", "instructionsAutoSave", translations_en.instructionsAutoSave);
i18n.addResourceBundle("en", "validations", translations_en.validations);

i18n.addResourceBundle("nl", "common", translations_nl.common);
i18n.addResourceBundle("nl", "entities", translations_nl.entities);
i18n.addResourceBundle("nl", "errors", translations_nl.errors);
i18n.addResourceBundle("nl", "properties", translations_nl.properties);
i18n.addResourceBundle("nl", "notifications", translations_nl.notifications);
i18n.addResourceBundle("nl", "texts", translations_nl.texts);
i18n.addResourceBundle("nl", "safeEditMode", translations_nl.safeEditMode);
i18n.addResourceBundle("nl", "instructionsAutoSave", translations_nl.instructionsAutoSave);
i18n.addResourceBundle("nl", "validations", translations_nl.validations);

export default i18n;
