import { Transfer } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Entity } from "../../../models/data/Entity";
import { Region, RegionType } from "../../../models/reference_lists/Region";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsBackButton, CmsSaveButton } from "../../common/ButtonComponents";
import { CmsForm, CmsFormItem } from "../../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../../common/PageComponents";
import { useQueryCurrencies } from "../../../queries/currencies/lists";
import { useQueryRegion, useSaveRegion } from "../../../queries/regions/detail";
import { useQueryCountries } from "../../../queries/countries/lists";
import { useCmsContext } from "../../../context/app/CmsContext";
import { useForm } from "react-hook-form";
import useRules from "../../../hooks/useRules";
import ControlledMultilingualText from "../../form/ControlledMultilingualText";
import ControlledInput from "../../form/ControlledInput";
import ControlledSelect from "../../form/ControlledSelect";

export const RegionDetails = () => {
  const { t } = useTranslation();
  const { canUpdate } = useAuthorization("region");
  const { push } = useHistory();
  const { id: regionId } = useParams<Entity>();
  const context = useCmsContext();
  const { required, mlTextRequired } = useRules();

  const {
    data: region,
    isLoading: isLoadingRegion,
    refetch: refetchRegion,
  } = useQueryRegion(regionId);
  const { mutateAsync: saveRegion, isLoading: isSavingRegion } = useSaveRegion();
  const { data: currencies, isLoading: isLoadingCurrencies } = useQueryCurrencies();
  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();

  React.useEffect(() => {
    if (region) {
      context?.setDuplicateErrorMessage(t("errors:duplicateRegion", { isoCode: region.isoCode }));

      context?.setBreadcrumbItems([
        {
          key: "region",
          name: getLocalizedValue(region.name) ?? t("common:new"),
        },
      ]);
    }
  }, [region, t]);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
    getValues,
  } = useForm<Region>({
    mode: "onChange",
    values: region,
  });

  const [countryTransfer, setCountryTransfer] = useState<string[]>(getValues().countryIds);
  const [currencyTransfer, setCurrencyTransfer] = useState<string[]>(getValues().currencyIds);

  const isLoadingAny =
    (isLoadingRegion && !!regionId) || isLoadingCountries || isLoadingCurrencies || isSavingRegion;
  const noDataAny = (!region && !!regionId) || !countries || !currencies;

  const submit = async (region: Region) => {
    region.countryIds = countryTransfer;
    region.currencyIds = currencyTransfer;

    await saveRegion(region);
    !region.id ? push(`/regions`) : await refetchRegion();
  };

  if (isLoadingAny || noDataAny) {
    return (
      <CmsPageLoader
        loading={true}
        key={"keycloak-pageloader"}
        title={t("common:loadingData")}
        subTitle={t("common:pleaseHold")}
      />
    );
  }

  return (
    <>
      <Prompt when={isDirty} message={t("common:unsavedChanges")} />
      <CmsPageHeader
        title={t("entities:region")}
        extra={[
          <CmsBackButton key="back" disabled={isLoadingAny} onClick={() => push(`/regions`)} />,
          !!canUpdate && (
            <CmsSaveButton
              key="save"
              disabled={!isValid || isLoadingAny}
              loading={isSubmitting}
              onClick={handleSubmit(submit)}
            />
          ),
        ]}
      />

      <CmsForm>
        <ControlledMultilingualText
          control={control}
          name={"name"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:name")}
          rules={{
            ...mlTextRequired(t("properties:name")),
          }}
        />
        <ControlledInput
          control={control}
          name={"isoCode"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:isoCode")}
          rules={{
            ...required(t("properties:isoCode")),
          }}
        />

        <ControlledSelect
          control={control}
          name={"regionType"}
          isLoading={isLoadingAny}
          canUpdate={canUpdate}
          label={t("properties:regionType")}
          data={[
            { name: "None", value: RegionType.NotSpecified },
            { name: "Global", value: RegionType.Global },
            { name: "Monetary", value: RegionType.Monetary },
          ]}
        />

        <CmsFormItem label={t("entities:countries")}>
          <Transfer
            dataSource={countries.map((country) => ({
              key: country.id,
              title: getLocalizedValue(country.name),
            }))}
            disabled={!canUpdate || isLoadingAny}
            targetKeys={countryTransfer ?? getValues().countryIds}
            onChange={(value) => setCountryTransfer(value as string[])}
            render={(item) => item.title as any}
          />
        </CmsFormItem>

        <CmsFormItem label={t("entities:currencies")}>
          <Transfer
            dataSource={currencies.map((currency) => ({
              key: currency.id,
              title: currency.name,
            }))}
            disabled={!canUpdate || isLoadingAny}
            targetKeys={currencyTransfer ?? getValues().currencyIds}
            onChange={(value) => setCurrencyTransfer(value as string[])}
            render={(item) => item.title as any}
          />
        </CmsFormItem>
      </CmsForm>
    </>
  );
};
