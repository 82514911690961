import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Currency } from "../../models/reference_lists/Currency";
import { CONST_CURRENCIES } from "../../utilities/constants/query-contants";

export const getCurrencies = (): Promise<Currency[]> => {
  return api.get({
    path: CONST_CURRENCIES.plural,
  });
};

export const useQueryCurrencies = () => {
  return useQuery<Currency[]>([CONST_CURRENCIES.plural], () => getCurrencies());
};
