import Config from "../../Config";
import i18n from "../../i18n";
import { MultilingualInputData } from "../../models/MultilingualInputData";

export const validateMlInput = (value: MultilingualInputData, name: string) => {
  if (value && value[Config.defaultLanguage] && value[Config.defaultLanguage].length > 0) {
    return true;
  }

  return i18n.t("validations:inputDefaultLanguageRequired", {
    property: name,
  });
};
