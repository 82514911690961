import { Country, CountryDocumentSupplier } from "../../models/reference_lists/Country";
import { DocumentType } from "../../models/reference_lists/DocumentType";

export const MapCountryDocumentSuppliers = (
  documentTypes: DocumentType[],
  countries: Country[],
  type: "usedBy" | "suppliedBy",
  language: string,
  data?: CountryDocumentSupplier[],
): {
  documentTypeId?: string;
  country?: string;
  documentTypeCode?: string;
  documentType?: string;
}[] => {
  if (!data) {
    return [];
  }

  return data.map((item) => {
    const documentType = documentTypes.find(
      (documentType) => documentType.id === item.documentTypeId,
    );

    return {
      documentTypeId: item.documentTypeId,
      country: countries.find((country) =>
        type === "suppliedBy"
          ? country.id === item.supplierCountryId
          : country.id === item.countryId,
      )?.name[language],
      documentType: documentType?.caption[language],
      documentTypeCode: documentType?.code,
    };
  });
};
