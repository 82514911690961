import { Attribute, AttributeDataType } from "../../models/templates/Attribute";
import { QueryStringParams } from "../../queries/document-series/document-series-type";
import { capitalizeFirstLetter } from "../StringHelper";

export const formatIndexFilter = (attr: Attribute) => {
  const constructed = constructIndexKey(attr.name!);

  switch (attr.dataType) {
    case AttributeDataType.Country:
    case AttributeDataType.Currency:
    case AttributeDataType.DocumentType:
    case AttributeDataType.MaterialType:
    case AttributeDataType.Organization:
    case AttributeDataType.Province:
    case AttributeDataType.Product:
    case AttributeDataType.Region:
      return `${constructed.join("")}Id`;

    default:
      return constructed.join("");
  }
};

export const constructIndexKey = (name: string): string[] => {
  const splitName = name.split(" ");

  return splitName.map((item, index) => {
    if (index === 0) {
      return item.toLowerCase();
    }

    return capitalizeFirstLetter(item);
  });
};

export const constructDateRange = (start?: string, end?: string) => {
  const sanatizedStart = start ? start.split("-").join("") : "*";
  const sanatizedEnd = end ? end.split("-").join("") : "*";

  return `[${sanatizedStart} TO ${sanatizedEnd}]`;
};

export const constructIndexQuery = (filters: QueryStringParams) => {
  const query = [] as string[];

  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined) {
      query.push(`${key}:${value}`);
    }
  });

  return query.join(" AND ");
};

export const sanatizeGuid = (id: string) => (id as string).split("-").join("");
