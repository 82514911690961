import { useTranslation } from "react-i18next";
import { CmsContent, CmsPageHeader, CmsPageLoader, CmsTable } from "../common/PageComponents";
import { Col, DatePicker, Layout, Radio, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { CmsButton } from "../common/ButtonComponents";
import { RadioBox } from "../common/RadioBox";
import { FiltersBox } from "../common/FiltersBox";
import { DocumentTemplate } from "../../models/templates/DocumentTemplate";
import { getLocalizedValue } from "../../utilities/MultilingualHelper";
import parser from "lucene-query-parser";
import dayjs from "dayjs";
import {
  FilterOption,
  SearchSetting,
  emptyFilters,
  emtpyDateFilter,
  emtpySearchSetting,
  filterOptionKey,
  formLayout,
  getAttributeCaption,
  getAttributeDataList,
  getDocumentStatisticsConfig,
  getQueryByKey,
} from "../../utilities/configs/DocumentStatisticsConfig";
import { CmsColLayout, CmsForm, CmsFormItem } from "../common/FormComponents";
import Search from "antd/es/input/Search";
import Title from "antd/es/typography/Title";
import { CmsSearchSettingsModal } from "./CmsSearchSettingsModal";
import { capitalizeFirstLetter } from "../../utilities/StringHelper";
import { useQueryDocumentTemplates } from "../../queries/document-template/lists";
import { useQueryCountries } from "../../queries/countries/lists";
import { useQueryRegions } from "../../queries/regions/lists";
import { useQueryDocumentTypes } from "../../queries/document-types/lists";
import { useQueryMaterialTypes } from "../../queries/material-types/lists";
import { useExportQuerySearch, useQuerySearch } from "../../queries/search/detail";
import { useQueryAttributes } from "../../queries/attributes/lists";
import { useQueryProvinces } from "../../queries/provinces/list";
import { useQuerySearchSettings } from "../../queries/search-settings/list";
import { useSaveSearchSetting } from "../../queries/search-settings/detail";

type IndexColumn = {
  title: string;
  dataIndex: string;
  render?: (text: any) => string;
};

export const DocumentStatistics = () => {
  const { t } = useTranslation();
  const fixedColumns: IndexColumn[] = useMemo(
    () => [
      { title: t("properties:code"), dataIndex: "code" },
      { title: t("entities:documentType"), dataIndex: "documentType" },
    ],
    [t],
  );

  const [error, setError] = useState<string | null>(null);
  const [exportOptions, setExportOptions] = useState<string>("count");
  const [searchSetting, setSearchSetting] = useState<{
    modal: boolean;
    data?: SearchSetting;
  }>(emtpySearchSetting);
  const [columns, setColumns] = useState<IndexColumn[]>([]);
  const [fields, setFields] = useState<string[]>([]);
  const [inputType, setInputType] = useState<string>("interface");
  const [dateFilter, setDateFilter] = useState<{
    start?: string;
    end?: string;
  }>(emtpyDateFilter);

  const [filters, setFilters] = useState<{
    product?: string;
    region?: string;
    country?: string;
    state?: string;
    documentType?: string;
    circulationStatus?: string;
    primaryPhotoTechnique?: string;
    textualPersonalisation?: string;
    seriesYear?: string;
    materialType?: string;
    hasManipulatedImages?: string;
    acquisitionSource?: string;
    collectorsItem?: string;
    commemorativeNote?: string;
    images?: string;
    securityFeatures?: string;
    startDate?: Date;
    endDate?: Date;
    documentStatus?: string;
    inVault?: string;
    isLent?: string;
  }>(emptyFilters);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { data: documentTemplates, isLoading: isLoadingDocumentTemplate } =
    useQueryDocumentTemplates();
  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();
  const { data: regions, isLoading: isLoadingRegions } = useQueryRegions();
  const { data: provinces, isLoading: isLoadingProvinces } = useQueryProvinces();
  const { data: documentTypes, isLoading: isLoadingDocumentTypes } = useQueryDocumentTypes();
  const { data: materialTypes, isLoading: isLoadingMaterialTypes } = useQueryMaterialTypes();
  const { data: searchResponse, isLoading: isLoadingSearch } = useQuerySearch(searchQuery);
  const { mutateAsync: exportQuerySearch, isLoading: isLoadingExportQuerySearch } =
    useExportQuerySearch();
  const {
    data: searchSettings,
    isLoading: isLoadingSearchSettings,
    refetch: refetchSearchSettings,
  } = useQuerySearchSettings();
  const { mutateAsync: saveSearchSetting, isLoading: isSavingSearchSetting } =
    useSaveSearchSetting();
  const { data: attributes, isLoading: isLoadingAttributes } = useQueryAttributes();

  const isLoadingAny =
    isLoadingCountries ||
    isLoadingDocumentTemplate ||
    isLoadingRegions ||
    isLoadingProvinces ||
    isLoadingDocumentTypes ||
    isLoadingMaterialTypes ||
    isLoadingAttributes ||
    isLoadingSearchSettings ||
    isLoadingExportQuerySearch;

  const filtersActive = Object.values(filters).some((value) => value !== undefined);

  useEffect(() => {
    if (searchResponse) {
      const fields: string[] = [];
      const fixedFields = fixedColumns.map((column) => column.dataIndex);

      searchResponse.forEach((result) => {
        if (result) {
          for (const field in result) {
            if (field && !fields.includes(field) && !fixedFields.includes(field)) {
              fields.push(field);
            }
          }
        }
      });

      setFields(fields);
    }
  }, [fixedColumns, searchResponse]);

  const constructQuery = (): string => {
    const query = [] as string[];

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined) {
        query.push(
          getQueryByKey(
            key as filterOptionKey,
            getDocumentStatisticsConfig(key as filterOptionKey).type === "list"
              ? (value as string).split("-").join("")
              : value,
          ),
        );
      }
    });

    if (Object.values(dateFilter).some((item) => item !== undefined)) {
      const start = dateFilter.start ? (dateFilter.start as string).split("-").join("") : "*";
      const end = dateFilter.end ? (dateFilter.end as string).split("-").join("") : null;

      query.push(`publishingDate:[${start} TO ${end}]`);
    }

    return query.join(" AND ");
  };

  const handleColumnChange = (values: string[]) => {
    const columns = [] as Array<IndexColumn>;

    values.forEach((value: any) => {
      columns.push({
        title: `${value}`,
        dataIndex: `${value}`,
      });
    });

    setColumns(columns);
  };

  const mergeDocumentTemplate = (): FilterOption[] => {
    const values = [] as string[];
    const returnValue = [] as FilterOption[];

    documentTemplates?.forEach((template) => {
      if (template.name?.toLocaleLowerCase().includes("sided")) {
        values.push(template.id ?? "");
      } else {
        returnValue.push({
          name: template.name,
          value: template.id,
        });
      }
    });

    return values.length === 2
      ? returnValue.concat({
          name: `Documents`,
          value: `(${values[0]} OR ${values[1]})`,
        })
      : (documentTemplates ?? ([] as DocumentTemplate[])).map((template) => ({
          name: template.name,
          value: template.id,
        }));
  };

  return (
    <>
      <CmsPageHeader
        title={t("common:documentStatistics")}
        extra={
          <>
            <Radio.Group value={inputType} onChange={(e) => setInputType(e.target.value)}>
              <Radio.Button value={"interface"}>{t("common:interface")}</Radio.Button>
              <Radio.Button value={"manual"}>{t("common:advanced")}</Radio.Button>
            </Radio.Group>
          </>
        }
      />
      <Layout>
        <CmsContent>
          <Row>
            <Col flex={6}>
              <CmsPageLoader loading={isLoadingAny}>
                {inputType === "interface" && (
                  <>
                    <FiltersBox
                      title="Filter options"
                      onChange={(key, value) => {
                        const config = getDocumentStatisticsConfig(key as filterOptionKey);

                        if (config) {
                          if (columns.every((column) => column.dataIndex !== config.indexKey)) {
                            setColumns(
                              columns.concat({
                                title: key,
                                dataIndex: config.indexKey,
                              }),
                            );
                          }

                          setFilters((prevState) => ({
                            ...prevState,
                            [key]: value,
                          }));
                        }
                      }}
                      data={[
                        {
                          key: "product",
                          data: mergeDocumentTemplate(),
                          name: t("entities:product"),
                          defaultValue: filters.product,
                        },
                        {
                          key: "country",
                          data: countries?.map((country) => ({
                            name: getLocalizedValue(country.name),
                            value: country.id,
                          })),
                          name: t("entities:country"),
                          defaultValue: filters.country,
                        },
                        {
                          key: "region",
                          data: regions?.map((region) => ({
                            name: getLocalizedValue(region.name),
                            value: region.id,
                          })),
                          name: t("entities:productionRegion"),
                          defaultValue: filters.region,
                        },
                        {
                          key: "state",
                          data: provinces?.map((province) => ({
                            name: province.name,
                            value: province.id,
                          })),
                          name: t("entities:province"),
                          defaultValue: filters.state,
                        },
                        {
                          key: "documentType",
                          data: documentTypes?.map((documentType) => ({
                            name: documentType.name,
                            value: documentType.id,
                          })),
                          name: t("entities:documentType"),
                          defaultValue: filters.documentType,
                        },
                        {
                          key: "circulationStatus",
                          name: t("entities:circulationStatus"),
                          defaultValue: filters.circulationStatus,
                        },
                        {
                          key: "primaryPhotoTechnique",
                          name: getAttributeCaption("Primary photo technique", attributes),
                          defaultValue: filters.primaryPhotoTechnique,
                          data: getAttributeDataList("Primary photo technique", attributes),
                        },
                        {
                          key: "textualPersonalisation",
                          name: getAttributeCaption("Textual personalisation", attributes),
                          defaultValue: filters.textualPersonalisation,
                          data: getAttributeDataList("Textual personalisation", attributes),
                        },
                        {
                          key: "seriesYear",
                          name: capitalizeFirstLetter(
                            getAttributeCaption("Series year", attributes),
                          ),
                          defaultValue: filters.seriesYear,
                        },
                        {
                          key: "materialType",
                          data: materialTypes?.map((materialType) => ({
                            name: materialType.name,
                            value: materialType.id,
                          })),
                          name: t("entities:materialType"),
                          defaultValue: filters.materialType,
                        },
                        {
                          key: "hasManipulatedImages",
                          name: capitalizeFirstLetter(
                            getAttributeCaption("Has manipulated images", attributes),
                          ),
                          defaultValue: filters.hasManipulatedImages,
                        },
                        {
                          key: "acquisitionSource",
                          name: t("properties:acquisitionSource"),
                          defaultValue: filters.acquisitionSource,
                        },
                        {
                          key: "collectorsItem",
                          name: capitalizeFirstLetter(
                            getAttributeCaption("Collectors item", attributes),
                          ),
                          defaultValue: filters.collectorsItem,
                        },
                        {
                          key: "commemorativeNote",
                          name: capitalizeFirstLetter(
                            getAttributeCaption("Commemorative note", attributes),
                          ),
                          defaultValue: filters.commemorativeNote,
                        },
                        {
                          key: "documentStatus",
                          name: t("properties:status"),
                          defaultValue: filters.documentStatus,
                        },
                        {
                          key: "inVault",
                          name: t("properties:inVault"),
                          defaultValue: filters.inVault,
                        },
                        {
                          key: "isLent",
                          name: t("properties:isLent"),
                          defaultValue: filters.isLent,
                        },
                        {
                          key: "worldRegion",
                          data: regions?.map((region) => ({
                            name: getLocalizedValue(region.name),
                            value: region.id,
                          })),
                          name: t("entities:worldRegion"),
                          defaultValue: filters.region,
                        },
                      ]}
                    />
                    <CmsPageLoader loading={false} subTitle={t("common:loadingData")}>
                      <Row>
                        <Col {...CmsColLayout}>
                          <CmsForm {...formLayout}>
                            <CmsFormItem
                              key={"date-range"}
                              label={t("entities:dateRange")}
                              labelAlign={"right"}
                            >
                              <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "50%" }}
                                defaultValue={
                                  dateFilter?.start
                                    ? dayjs(dateFilter.start, "YYYY-MM-DD")
                                    : undefined
                                }
                                onChange={(_, value) =>
                                  setDateFilter((prevState) => ({
                                    ...prevState,
                                    start: value !== "" ? (value as string) : undefined,
                                  }))
                                }
                              />
                              <DatePicker
                                format="YYYY-MM-DD"
                                style={{ width: "50%" }}
                                defaultValue={
                                  dateFilter?.end ? dayjs(dateFilter.end, "YYYY-MM-DD") : undefined
                                }
                                onChange={(_, value) =>
                                  setDateFilter((prevState) => ({
                                    ...prevState,
                                    end: value !== "" ? (value as string) : undefined,
                                  }))
                                }
                              />
                            </CmsFormItem>
                          </CmsForm>
                        </Col>
                        <Col {...CmsColLayout}>
                          <CmsForm {...formLayout}>
                            <CmsFormItem
                              key={"select-all-security-feature-counts"}
                              label={t("entities:allSecurityFeatureCounts")}
                              labelAlign={"right"}
                            >
                              <CmsButton
                                buttonType="add"
                                disabled={!!searchResponse ? searchResponse.length === 0 : true}
                                onClick={() => {
                                  const additions = [] as IndexColumn[];
                                  fields.forEach((field) => {
                                    if (
                                      field.includes("securityfeaturecount") &&
                                      columns.every((column) => column.dataIndex !== field)
                                    ) {
                                      additions.push({
                                        title: field,
                                        dataIndex: field,
                                      });
                                    }
                                  });

                                  setColumns(columns.concat(additions));
                                }}
                              />
                            </CmsFormItem>
                            <CmsFormItem
                              key={"select-all-field-actives"}
                              label={t("entities:allFieldsSelectionStatus")}
                              labelAlign={"right"}
                            >
                              <CmsButton
                                buttonType="add"
                                disabled={!!searchResponse ? searchResponse.length === 0 : true}
                                onClick={() => {
                                  const additions = [] as IndexColumn[];
                                  fields.forEach((field) => {
                                    if (
                                      field.includes("fieldselectionstatus") &&
                                      columns.every((column) => column.dataIndex !== field)
                                    ) {
                                      additions.push({
                                        title: field,
                                        dataIndex: field,
                                      });
                                    }
                                  });

                                  setColumns(columns.concat(additions));
                                }}
                              />
                            </CmsFormItem>
                            <CmsFormItem
                              key={"select-all-image-actives"}
                              label={t("entities:allImagesSelectionStatus")}
                              labelAlign={"right"}
                            >
                              <CmsButton
                                buttonType="add"
                                disabled={!!searchResponse ? searchResponse.length === 0 : true}
                                onClick={() => {
                                  const additions = [] as IndexColumn[];
                                  console.log(fields);

                                  fields.forEach((field) => {
                                    if (
                                      field.includes("imagetemplatesselected") &&
                                      columns.every((column) => column.dataIndex !== field)
                                    ) {
                                      additions.push({
                                        title: field,
                                        dataIndex: field,
                                      });
                                    }
                                  });

                                  setColumns(columns.concat(additions));
                                }}
                              />
                            </CmsFormItem>
                          </CmsForm>
                        </Col>
                      </Row>
                    </CmsPageLoader>

                    <CmsButton
                      buttonType="send"
                      disabled={!filtersActive || (!!searchQuery && isLoadingSearch)}
                      onClick={() => setSearchQuery(constructQuery())}
                    />
                  </>
                )}
                {inputType === "manual" && (
                  <div style={{ paddingRight: "10px" }}>
                    <CmsFormItem label="" error={error}>
                      <Search
                        placeholder={t("common:searchQuery")}
                        onSearch={async (query) => {
                          if (!query) {
                            setError(t("validations:inputRequired"));
                            return;
                          }

                          try {
                            parser.parse(query);
                          } catch (e) {
                            const error = e as Error;
                            setError(error.message);
                            return;
                          }

                          setSearchQuery(query);

                          setError(null);
                        }}
                        enterButton
                        loading={isLoadingAny}
                      />
                    </CmsFormItem>
                  </div>
                )}
              </CmsPageLoader>
            </Col>
            <Col flex={1}>
              <CmsPageLoader loading={isLoadingSearchSettings || isSavingSearchSetting}>
                <RadioBox
                  title={t("common:exportOptions")}
                  defaultValue={exportOptions}
                  options={[
                    { value: "count", caption: t("common:exportOptionsCount") },
                    { value: "list", caption: t("common:exportOptionsList") },
                  ]}
                  onChange={(value) => setExportOptions(value)}
                />
                <CmsButton
                  buttonType="download"
                  disabled={
                    exportOptions === "count" ||
                    !filtersActive ||
                    (!!searchQuery && isLoadingSearch)
                  }
                  style={{ display: "block", marginTop: "10px" }}
                  onClick={async () => {
                    await exportQuerySearch({
                      query: constructQuery(),
                      csvFields: fixedColumns.concat(columns).map((column) => column.dataIndex),
                    });
                  }}
                />
                <div style={{ paddingTop: "10px" }}>
                  <Select
                    style={{ width: "100%" }}
                    placeholder={t("common:exportOptionsSettingSelect")}
                    value={searchSetting.data?.id}
                    allowClear
                    onChange={async (id) => {
                      const data = searchSettings?.find((setting) => setting.id === id);

                      setSearchSetting((prevState) => ({
                        ...prevState,
                        data: data,
                      }));

                      if (data !== undefined) {
                        const parsedFields = JSON.parse(data.fields) as IndexColumn[];
                        setFilters(JSON.parse(data.searchString));
                        setColumns(parsedFields);

                        if (!!fields && fields.length === 0) {
                          setFields(parsedFields.map((field) => field.dataIndex));
                        }

                        await refetchSearchSettings();
                      }
                    }}
                  >
                    {searchSettings?.map((setting) => (
                      <Select.Option key={`field_${setting.id}`} value={setting.id}>
                        {setting.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <CmsButton
                    buttonType="save"
                    disabled={!!searchSetting.data?.id}
                    onClick={async () => {
                      setSearchSetting((prevState) => ({
                        ...prevState,
                        modal: true,
                      }));
                    }}
                  >
                    Save
                  </CmsButton>
                  <CmsButton
                    buttonType="save"
                    disabled={!searchSetting.data?.id}
                    onClick={async () => {
                      if (searchSetting.data) {
                        await saveSearchSetting({
                          id: searchSetting.data.id,
                          fields: JSON.stringify(columns),
                          name: searchSetting.data.name,
                          searchString: JSON.stringify(filters),
                        });
                        await refetchSearchSettings();
                      }
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Edit
                  </CmsButton>
                  <CmsButton
                    buttonType="default"
                    icon={<CloseOutlined />}
                    disabled={
                      Object.values(filters).every((item) => item === undefined) &&
                      columns.length === 0
                    }
                    danger
                    onClick={async () => {
                      setFilters(emptyFilters);
                      setColumns([]);
                      setSearchSetting(emtpySearchSetting);
                      setDateFilter(emtpyDateFilter);
                      await refetchSearchSettings();
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    reset
                  </CmsButton>
                </div>
              </CmsPageLoader>
            </Col>
          </Row>

          <CmsPageLoader loading={isLoadingAny} style={{ paddingTop: "20px" }}>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder={t("common:columns")}
              value={columns.map((col) => col.dataIndex)}
              defaultValue={columns.map((col) => col.dataIndex)}
              onChange={(values) => handleColumnChange(values)}
            >
              {fields.map((field, dataIndex) => (
                <Select.Option key={`field_${dataIndex}`} value={field}>
                  {field}
                </Select.Option>
              ))}
            </Select>
          </CmsPageLoader>

          {exportOptions === "count" && (
            <div style={{ paddingTop: "20px" }}>
              <Title>
                {t("common:documentSearchResultNumber", {
                  number: !!searchResponse ? searchResponse.length : 0,
                })}
              </Title>
            </div>
          )}

          {exportOptions === "list" && (
            <div style={{ paddingTop: "20px", overflowX: "auto" }}>
              <CmsTable
                loading={isLoadingSearch}
                dataSource={!!searchResponse && searchResponse.length > 0 ? searchResponse : []}
                rowKey="id"
                columns={fixedColumns.concat(columns)}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    t("common:rangeOfItems", {
                      range: `${range[0]}-${range[1]}`,
                      total,
                    }),
                  pageSizeOptions: ["10", "20", "50", "100"],
                }}
              />
            </div>
          )}
        </CmsContent>
        <CmsSearchSettingsModal
          name={searchSetting.data?.name}
          visible={searchSetting.modal}
          onSave={async (values) => {
            await saveSearchSetting({
              fields: JSON.stringify(columns),
              name: values.name as string,
              searchString: JSON.stringify(filters),
            });
            await refetchSearchSettings();
            setSearchSetting((prevState) => ({
              ...prevState,
              modal: false,
            }));
          }}
          onClose={() =>
            setSearchSetting((prevState) => ({
              ...prevState,
              modal: false,
            }))
          }
        />
      </Layout>
    </>
  );
};
