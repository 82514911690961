import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "../../styles/search.css";
import { useQuerySimpleSearch } from "../../queries/search/detail";
import { SearchResult } from "../../queries/search/search-types";
import React from "react";

export const SearchBox = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [search, setSearch] = React.useState<string>();
  const { data: results, isLoading: isSearching } = useQuerySimpleSearch(search);

  const renderOptions = (results: SearchResult[] | null) => {
    if (results && results.length > 0) {
      return results.map((result) => ({
        ...result,
        value: result.name,
        key: `search_result_${result.id}`,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text ellipsis>{result.name}</Text>
            <span style={{ fontSize: "8", marginRight: 0 }}>
              <Tag color={"blue"} style={{ marginRight: 0 }}>
                {getFriendlyName(result.type)}
              </Tag>
            </span>
          </div>
        ),
      }));
    }

    if (results && search) {
      return [
        {
          id: "no-results",
          key: "no-results",
          type: "no-results",
          name: "no-results",
          label: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {t("common:noResults")}
            </div>
          ),
          value: t("common:noResults"),
        },
      ];
    }

    return [];
  };

  const handleSearch = (value: string) => setSearch(value);

  const getFriendlyName = (type: string) =>
    t(`entities:${type.charAt(0).toLowerCase() + type.slice(1)}`);

  const handleSelect = async (
    _: string,
    option: { id: string; type?: string; name?: string; extensionId?: string },
  ) => {
    const url = (option.type as string)
      .split(/(?=[A-Z])/)
      .join("-")
      .toLocaleLowerCase();

    const constructedUrl = option.extensionId ? `${option.extensionId}/${option.id}` : option.id;

    history.push(`/${url}/${constructedUrl}`);

    setSearch(undefined);
  };

  return (
    <div className="certain-category-search-wrapper" style={{ width: 400 }} key="search">
      <AutoComplete
        className="certain-category-search"
        dropdownClassName="certain-category-search-dropdown"
        dropdownStyle={{ width: 400 }}
        style={{ width: "100%" }}
        listHeight={500}
        value={search}
        options={renderOptions(results)}
        onChange={handleSearch}
        onSelect={handleSelect}
        placeholder={t("common:search")}
      >
        <Input
          suffix={
            isSearching && !!search ? (
              <LoadingOutlined className="certain-category-icon" spin />
            ) : (
              <SearchOutlined className="certain-category-icon" />
            )
          }
        />
      </AutoComplete>
    </div>
  );
};
