import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { Field } from "../../../models/templates/Field";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { CmsCreateButton } from "../../common/ButtonComponents";
import { GroupManager } from "../../common/GroupManager";
import {
  CmsContent,
  CmsPageHeader,
  CmsSearch,
  CmsSider,
  CmsTable,
} from "../../common/PageComponents";
import { ITreeViewItem, TreeView } from "../../common/TreeView";
import { useQueryFields } from "../../../queries/fields/lists";
import { useDeleteField } from "../../../queries/fields/detail";
import { useQueryFieldGroups } from "../../../queries/field-groups/lists";
import { useCmsContext } from "../../../context/app/CmsContext";
import { GroupType } from "../../../queries/groups/group-types";

export const FieldList = () => {
  const { t } = useTranslation();
  const { canCreate, canUpdate, canDelete, canView } = useAuthorization("field");
  const { push } = useHistory();
  const context = useCmsContext();
  const [state, setState] = useState<{
    selectedGroup?: string;
    expandedGroups?: string[];
    groupsManagerVisible: boolean;
  }>({
    selectedGroup: sessionStorage.getItem("currentFieldGroup") ?? undefined,
    expandedGroups: undefined,
    groupsManagerVisible: false,
  });

  const {
    data: fields,
    isLoading: isLoadingFields,
    refetch: refetchFields,
  } = useQueryFields(state.selectedGroup);
  const { mutateAsync: deleteField, isLoading: isDeletingField } = useDeleteField();
  const {
    data: fieldGroups,
    isLoading: isLoadingFieldGroups,
    refetch: refetchFieldGroups,
  } = useQueryFieldGroups();

  const [fieldsFilter, setFieldsFilter] = React.useState<Field[]>(fields ?? []);

  React.useEffect(() => {
    if (!!fields) {
      setFieldsFilter(fields);

      const sessionKeys = sessionStorage.getItem("fieldGroupExpandedKeys");
      const keys = sessionKeys ? sessionKeys.split(",") : [];
      setState((prevState) => ({ ...prevState, fields, expandedGroups: keys }));
    }
  }, [fields]);

  const search = (searchText) => {
    if (fields) {
      const search = searchText.toLowerCase();
      const filteredFields = fields.filter((field) => {
        const name = field.name && field.name.toLowerCase();
        return name && name.includes(search);
      });

      setFieldsFilter(filteredFields);
    }
  };

  React.useEffect(() => {
    if (state.selectedGroup) {
      sessionStorage.setItem("currentFieldGroup", state.selectedGroup);
    }

    if (state.expandedGroups) {
      sessionStorage.setItem("fieldGroupExpandedKeys", state.expandedGroups.join(","));
    }
  }, [state.selectedGroup, state.expandedGroups]);

  React.useEffect(() => {
    if (fieldsFilter) {
      context?.setDuplicateErrorMessage(t("errors:fieldLinked"));
    }
  }, [fieldsFilter, t]);

  const isLoadingAny = isLoadingFields || isLoadingFieldGroups || isDeletingField;

  return (
    <React.Fragment>
      <CmsPageHeader
        title={t("entities:fields")}
        extra={[
          <CmsSearch key="search" onChange={search} />,
          canCreate || canUpdate ? (
            <Button
              key="manage"
              icon={<EditOutlined />}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  groupsManagerVisible: true,
                }))
              }
            >
              {t("common:manageGroups")}
            </Button>
          ) : null,
          canCreate ? <CmsCreateButton key="create" onClick={() => push(`/field`)} /> : null,
        ]}
      />
      <Layout>
        <CmsSider>
          <TreeView
            items={fieldGroups as ITreeViewItem[]}
            selectedKey={state.selectedGroup}
            expandedKeys={state.expandedGroups}
            onExpand={(keys) => setState((prevState) => ({ ...prevState, expandedGroups: keys }))}
            onSelect={(groupId) =>
              setState((prevState) => ({
                ...prevState,
                selectedGroup: groupId,
              }))
            }
          />
        </CmsSider>
        <CmsContent>
          <CmsTable
            loading={isLoadingAny}
            dataSource={fieldsFilter}
            rowKey="id"
            columns={[
              { title: t("properties:name"), dataIndex: "name" },
              { title: t("properties:code"), dataIndex: "code" },
              {
                title: t("properties:caption"),
                dataIndex: "caption",
                render: (caption) => getLocalizedValue(caption),
              },
            ]}
            onEdit={canUpdate || canView ? (id) => push(`/field/${id}`) : undefined}
            onDelete={
              canDelete
                ? async (id) => {
                    await deleteField(id);
                    await refetchFields();
                  }
                : undefined
            }
          />
        </CmsContent>
      </Layout>
      <Drawer
        placement="left"
        open={state.groupsManagerVisible}
        width={600}
        onClose={async () => {
          await refetchFieldGroups();
          await refetchFields();
          setState((prevState) => ({
            ...prevState,
            groupsManagerVisible: false,
          }));
        }}
        title={t("common:manageGroups")}
      >
        <GroupManager visible={state.groupsManagerVisible} groupType={GroupType.Field} />
      </Drawer>
    </React.Fragment>
  );
};
