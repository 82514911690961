import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { CopyInstructionsModal } from "./CopyInstructionsModal";
import { InstructionsList } from "./InstructionsList";

export const DocumentInstructions = ({
  documentTemplateType,
}: {
  documentTemplateType?: string;
}) => {
  const { canCopy } = useAuthorization("document");

  const [state, setState] = useState<{
    copyInstructionsModalVisible: boolean;
  }>({
    copyInstructionsModalVisible: false,
  });

  const { t } = useTranslation();

  const { values: document, setFieldValue } = useFormikContext<DocumentSeries>();

  return (
    <React.Fragment>
      {canCopy && (
        <Button
          type="primary"
          icon={<CopyOutlined />}
          style={{ marginBottom: "12px" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: true,
            }));
          }}
        >
          {t("common:copyTexts")}
        </Button>
      )}
      {document.instructions && (
        <InstructionsList
          instructionsList={document.instructions}
          onChange={(instructions) => setFieldValue("instructions", instructions)}
          richText={
            documentTemplateType === "Genuine banknote" ||
            documentTemplateType === "Counterfeit banknote"
          }
          instructionLocationOptions={["NOT_SPECIFIED", "DOCUMENT_NOTIFICATION"]}
        />
      )}
      {state.copyInstructionsModalVisible ? (
        <CopyInstructionsModal
          onImport={(instructions) => {
            setFieldValue("instructions", instructions);
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: false,
            }));
          }}
          instructionType={"instructions"}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              copyInstructionsModalVisible: false,
            }))
          }
        />
      ) : null}
    </React.Fragment>
  );
};
