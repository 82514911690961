import { AttributeValue } from "../../models/data/AttributeValue";
import { AttributeLink } from "../../models/templates/AttributeLink";

export const attributeMap = {
  denomination: "Denomination",
  inCirculation: "In circulation",
  seriesYear: "Series year",
  series: "Series",
  dateInCirculation: "Date in circulation",
  issuedByCountry: "Issued by country",
  issuedByRegion: "Issued by region",
  pageNumber: "Page number",
  currency: "Currency",
  documentCategory: "Document category",
  ages: "Ages",
} as Record<AttributeKeyMap, string>;

export type AttributeKeyMap =
  | "denomination"
  | "inCirculation"
  | "seriesYear"
  | "series"
  | "dateInCirculation"
  | "issuedByCountry"
  | "issuedByRegion"
  | "pageNumber"
  | "currency"
  | "documentCategory"
  | "ages";

export const getAttributeByKey = (
  key: AttributeKeyMap,
  attributes: AttributeValue[],
  templateAttributes: AttributeLink[],
): AttributeValue | undefined => {
  const attributeLinkId = getAttributeLink(key, templateAttributes);

  if (!attributeLinkId) return;

  return attributes
    ? attributes.find((attr) => attr.attributeLinkId === attributeLinkId)
    : undefined;
};

export const getAttributeLink = (key: AttributeKeyMap, templateAttributes: AttributeLink[]) => {
  return templateAttributes.find(
    (templateAttribute) => templateAttribute.attributeName === attributeMap[key],
  )?.id;
};

type Record<K extends keyof any, T> = {
  [P in K]: T;
};
