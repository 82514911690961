import { useQueryClient } from "@tanstack/react-query";
import { Notification } from "../../models/data/DC/Notification";
import api from "../../services/api";
import { CONST_NOTIFICATIONS } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";

export const postNotification = (payload: Notification) => {
  return api.post({
    path: CONST_NOTIFICATIONS.plural,
    body: {
      ...payload,
    },
  });
};

export const useCreateNotification = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: Notification) => postNotification(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_NOTIFICATIONS.plural]);

      notifySuccess(t("notifications:successCreatedNofication"));
    },
  });
};
