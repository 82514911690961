import { ScanOutlined, SettingOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Collapse, Tabs } from "antd";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../hooks/useAuthorization";
import { DocumentSeries } from "../../../models/data/Document";
import { View } from "../../../models/data/View";
import { ViewTemplate } from "../../../models/templates/ViewTemplate";
import { getLocalizedValue } from "../../../utilities/MultilingualHelper";
import { capitalizeFirstLetter } from "../../../utilities/StringHelper";
import { CmsTabs } from "../../common/PageComponents";
import { AttributeValueEntries } from "./AttributeValueEntries";
import { ImageDetails } from "./ImageDetails";
import { InstructionsList } from "./InstructionsList";
import { ScanOrder } from "./ScanOrder";
import { ViewProperties } from "./ViewProperties";
import { ViewsManager } from "./ViewsManager";
import { updateFormStatusWithSection } from "../../../utilities/helpers/document-helper";
const { Panel } = Collapse;
const { TabPane } = Tabs;

interface Props {
  viewTemplates: ViewTemplate[];
}

interface State {
  viewsManagerVisible: boolean;
  scanOrderVisible: boolean;
}

export const Views = (props: Props) => {
  const { canUpdate } = useAuthorization("document");

  const { t } = useTranslation();
  const { values, status, setFieldValue, setStatus, submitForm } =
    useFormikContext<DocumentSeries>();

  const [state, setState] = React.useState<State>({
    viewsManagerVisible: false,
    scanOrderVisible: false,
  });

  const renderPanels = () => {
    return props.viewTemplates.map((template) => {
      const viewIndex = values.views.findIndex((view) => view.viewTemplateId === template.id);
      if (viewIndex === -1) {
        return null;
      }

      const view = values.views[viewIndex];

      const filteredImages = view.images?.filter(
        (images) =>
          template.imageTemplates.find((it) => it.id === images.imageTemplateId)?.name !==
          "ORIGINAL",
      );

      const securityFeatureCount = filteredImages
        ?.map((image) => image.securityFeatureSelections)
        .reduce((a: any, b) => a.concat(b), []).length;

      const missingImages =
        filteredImages &&
        (filteredImages.length === 0 ||
          filteredImages.filter((image) => image.fileId === null).length > 0 ||
          filteredImages
            .map(
              (image) =>
                image.securityFeatureSelections !== undefined &&
                image.securityFeatureSelections.filter(
                  (selection) => selection.images !== undefined && selection.images.length === 0,
                ).length,
            )
            .reduce((partial: number, a) => partial + (a as number), 0));

      return (
        <Panel
          key={template.id as string}
          header={
            <>
              {capitalizeFirstLetter(
                (`${getLocalizedValue(template.caption)}  (${securityFeatureCount})` ||
                  template.name) ??
                  "",
              )}{" "}
              {missingImages ? (
                <WarningOutlined title={t("common:missingImage")} style={{ color: "red" }} />
              ) : undefined}
            </>
          }
        >
          <CmsTabs destroyInactiveTabPane={true}>
            <TabPane tab={t("common:images")} key="images">
              <CmsTabs defaultActiveKey="views">{renderImages(view, viewIndex, template)}</CmsTabs>
            </TabPane>
            <TabPane tab={t("common:attributes")} key="attributes">
              <ViewProperties viewIndex={viewIndex} />
              <AttributeValueEntries
                attributeLinks={template.attributeLinks}
                attributeValues={values.views[viewIndex].attributeValues ?? []}
                onChange={(attributeValues) =>
                  setFieldValue(`views[${viewIndex}].attributeValues`, attributeValues)
                }
                onValidityChanged={(isValid) =>
                  setStatus(
                    updateFormStatusWithSection(
                      status,
                      `view${viewIndex}-attributeValues`,
                      isValid,
                    ),
                  )
                }
              />
            </TabPane>
            <TabPane tab={t("common:instructions")} key="instructions" disabled={!canUpdate}>
              <InstructionsList
                instructionsList={values.views[viewIndex].instructions ?? []}
                onChange={(instructions) => {
                  setFieldValue(`views[${viewIndex}].instructions`, instructions);
                }}
              />
            </TabPane>
          </CmsTabs>
        </Panel>
      );
    });
  };

  const renderImages = (view: View, viewIndex: number, viewTemplate: ViewTemplate) => {
    return viewTemplate.imageTemplates.map((template) => {
      const imageIndex = view.images?.findIndex((image) => image.imageTemplateId === template.id);

      if (imageIndex !== -1) {
        return (
          <TabPane
            tab={getLocalizedValue(template.caption) || template.name}
            key={`tab_${template.id}`}
          >
            <ImageDetails
              key={`image_${template.id}`}
              documentTemplateId={values.documentTemplateId as string}
              imageTemplate={template}
              viewIndex={viewIndex}
              imageIndex={imageIndex as number}
            />
          </TabPane>
        );
      }
      return undefined;
    });
  };

  return (
    <React.Fragment>
      {canUpdate && (
        <Button
          type="primary"
          icon={<SettingOutlined />}
          style={{ marginBottom: "12px" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              viewsManagerVisible: true,
            }));
          }}
        >
          {t("common:manageViewsAndImages")}
        </Button>
      )}

      {canUpdate && (
        <Button
          type="primary"
          icon={<ScanOutlined />}
          style={{ marginBottom: "12px", marginLeft: "10px" }}
          onClick={() => {
            setState((prevState) => ({ ...prevState, scanOrderVisible: true }));
          }}
        >
          {t("common:scanOrder")}
        </Button>
      )}

      <Collapse destroyInactivePanel={true} accordion>
        {renderPanels()}
      </Collapse>

      <ViewsManager
        visible={state.viewsManagerVisible}
        viewTemplates={props.viewTemplates}
        views={values.views}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            viewsManagerVisible: false,
          }))
        }
        onSave={async (views) => {
          setFieldValue("views", views);
          setState((prevState) => ({
            ...prevState,
            viewsManagerVisible: false,
          }));

          await submitForm();
        }}
      />
      {state.scanOrderVisible && values.code ? (
        <ScanOrder
          documentId={values.id}
          documentCode={values.code}
          views={values.views}
          viewTemplates={props.viewTemplates}
          onClose={() => setState((prevState) => ({ ...prevState, scanOrderVisible: false }))}
        />
      ) : null}
    </React.Fragment>
  );
};
