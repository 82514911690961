import { DatePicker } from "antd";
import { CmsFormItem } from "../common/FormComponents";
import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import dayjs from "dayjs";

export default function ControlledDatePicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  isLoading,
  canUpdate,
  rules,
  dateFormat = "YYYY-MM-DD",
}: {
  control: Control<TFieldValues, any>;
  name: TName;
  label: string;
  isLoading: boolean;
  canUpdate: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  dateFormat?: string;
}) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isLoading || !canUpdate}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref, name, disabled },
        fieldState: { error },
      }) => (
        <CmsFormItem label={label} error={error?.message}>
          <DatePicker
            name={name}
            disabled={disabled}
            format={dateFormat}
            value={value ? dayjs(value, dateFormat) : null}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
          />
        </CmsFormItem>
      )}
    />
  );
}
