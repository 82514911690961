import { Button, Flex, Modal, Tooltip } from "antd";
import { CmsCancelButton, CmsSaveButton } from "../../../common/ButtonComponents";
import "cropperjs/dist/cropper.css";
import { useRef, useState } from "react";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import ButtonGroup from "antd/es/button/button-group";
import {
  ForwardOutlined,
  CompressOutlined,
  FullscreenOutlined,
  RetweetOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { CmsPageLoader } from "../../../common/PageComponents";

export const CropperModal = ({
  file,
  open,
  preSelection,
  title,
  onClose,
  onSave,
  multiple = false,
  isSubmitting,
}: {
  file: string;
  open: boolean;
  preSelection?: Cropper.Data;
  title?: string;
  onClose: () => void;
  onSave: (selection: Cropper.Data, another?: boolean) => void;
  multiple?: boolean;
  isSubmitting?: boolean;
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [selection, setSelection] = useState<Cropper.Data | undefined>(preSelection);

  return (
    <Modal
      width={1000}
      style={{ height: "750px", maxHeight: "80%" }}
      title={title ?? "Create/edit image crop"}
      open={open}
      closable={true}
      onCancel={() => onClose()}
      footer={[]}
      zIndex={1001}
    >
      {isSubmitting ? (
        <CmsPageLoader
          loading={true}
          key={"cropper-pageloader"}
          title={"Saving crop.."}
          subTitle={"On moment please"}
        />
      ) : (
        <>
          <Flex align="center" justify="space-between">
            <div>
              <ButtonGroup style={{ margin: "2px" }}>
                <Tooltip title="Crop">
                  <Button
                    type="primary"
                    icon={<CompressOutlined />}
                    onClick={() => cropperRef.current?.cropper.setDragMode("crop")}
                  />
                </Tooltip>
                <Tooltip title="Move">
                  <Button
                    type="primary"
                    icon={<FullscreenOutlined />}
                    onClick={() => cropperRef.current?.cropper.setDragMode("move")}
                  />
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup style={{ margin: "2px" }}>
                <Tooltip title="Reset">
                  <Button
                    type="primary"
                    icon={<RetweetOutlined />}
                    onClick={() => cropperRef.current?.cropper.reset()}
                  />
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup style={{ margin: "2px" }}>
                <Tooltip title="Zoom in">
                  <Button
                    type="primary"
                    icon={<ZoomInOutlined />}
                    onClick={() => cropperRef.current?.cropper.zoom(0.1)}
                  />
                </Tooltip>
                <Tooltip title="Zoom out">
                  <Button
                    type="primary"
                    icon={<ZoomOutOutlined />}
                    onClick={() => cropperRef.current?.cropper.zoom(-0.1)}
                  />
                </Tooltip>
              </ButtonGroup>
            </div>

            <div>
              <ButtonGroup>
                <CmsCancelButton key="cancel" onClick={() => onClose()} />
                <CmsSaveButton
                  key="save"
                  disabled={!selection}
                  onClick={() => {
                    if (!!selection) {
                      onSave(selection);
                    }
                  }}
                />
                {multiple && (
                  <Tooltip title={"Save and create another"}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        if (!!selection) {
                          onSave(selection, true);
                          cropperRef.current?.cropper.clear();
                        }
                      }}
                    >
                      <>
                        <SaveOutlined />
                        <ForwardOutlined />
                      </>
                    </Button>
                  </Tooltip>
                )}
              </ButtonGroup>
            </div>
          </Flex>

          <div style={{ maxHeight: "600px" }}>
            <ReactCropper
              ref={cropperRef}
              id="selection-area"
              src={file}
              background={false}
              autoCrop={!!selection}
              style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
              data={selection}
              crop={(crp) => setSelection(crp.detail)}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
