import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { CmsFormItem } from "../common/FormComponents";
import { Checkbox, CheckboxOptionType } from "antd";
import {
  OrganizationProductType,
  OrganizationType,
} from "../../models/reference_lists/Organization";

export default function ControlledCheckboxGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  isDisabled,
  options,
  rules,
}: {
  control: Control<TFieldValues, any>;
  name: TName;
  label: string;
  isDisabled: boolean;
  options?: (string | number | CheckboxOptionType<string>)[] | undefined;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}) {
  // TODO: change this to generic when needed
  const getTypeValues = (type: OrganizationType | OrganizationProductType) => {
    if (type) {
      return type
        .toString()
        .split(",")
        .map((type) => type.trim());
    }
  };

  // TODO: change this to generic when needed
  const setTypeValues = (keys: string[]) => keys.join(", ");

  return (
    <Controller
      control={control}
      name={name}
      disabled={isDisabled}
      rules={rules}
      render={({ field: { onChange, value, ref, name, disabled }, fieldState: { error } }) => (
        <CmsFormItem label={label} error={error?.message} required={true}>
          <Checkbox.Group
            name={name}
            options={options}
            value={getTypeValues(value)}
            disabled={disabled}
            onChange={(value) => onChange(setTypeValues(value))}
            ref={ref}
          />
        </CmsFormItem>
      )}
    />
  );
}
