import { UploadOutlined } from "@ant-design/icons";
import { Button, Popover, Table, Upload } from "antd";
import Modal from "antd/lib/modal/Modal";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import config from "../../../../Config";
import { useAuthorization } from "../../../../hooks/useAuthorization";
import { Note } from "../../../../models/data/Note";
import { IsImage } from "../../../../utilities/FileUtil";
import { CmsForm } from "../../../common/FormComponents";
import { RowButtonSet } from "../../../common/RowButtonSet";
import React from "react";
import { useUploadFile } from "../../../../queries/files/detail";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../form/ControlledInput";
import useRules from "../../../../hooks/useRules";
import ControlledTextArea from "../../../form/ControlledTextArea";

export const NoteDetails = ({
  note,
  onClose,
  onSave,
}: {
  note: Note;
  onClose: () => void;
  onSave: (note: Note) => void;
}) => {
  const { canUpdate } = useAuthorization("document");
  const { t } = useTranslation();
  const { required, maxLength } = useRules();

  const [state, setState] = React.useState<{
    files: RcFile[];
    isLoading: boolean;
    isChanged: boolean;
  }>({
    files: [],
    isLoading: false,
    isChanged: false,
  });

  const { mutateAsync: importNote, isLoading: isImportingNote } = useUploadFile();

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
    setValue,
    getValues,
  } = useForm<Note>({
    mode: "onChange",
    values: note,
  });

  const handleSave = async (value: Note) => {
    if (state.files) {
      for (const file of state.files) {
        const response = await importNote({ file });

        value.attachments?.push({
          fileId: response.id,
          fileName: response.fileName,
        });
      }

      onSave(value);
    }
  };

  return (
    <Modal
      open={true}
      width={700}
      title={t("entities:note")}
      onCancel={onClose}
      destroyOnClose={true}
      footer={[
        <Button id="btnCancel" key="cancel" onClick={onClose}>
          {t("common:cancel")}
        </Button>,
        <Button
          id="btnSave"
          key="save"
          type="primary"
          disabled={!isValid || !state.isChanged || isImportingNote || isSubmitting}
          onClick={handleSubmit(handleSave)}
        >
          {t("common:save")}
        </Button>,
      ]}
    >
      <CmsForm>
        <ControlledInput
          control={control}
          name={"title"}
          isLoading={false}
          canUpdate={canUpdate}
          label={t("properties:title")}
          rules={{
            ...required(t("properties:title")),
            ...maxLength(250, t("properties:title")),
          }}
        />

        <ControlledTextArea
          control={control}
          name={"description"}
          isLoading={false}
          canUpdate={canUpdate}
          label={t("properties:description")}
          rules={{
            ...required(t("properties:description")),
            ...maxLength(1000, t("properties:description")),
          }}
        />

        {canUpdate && (
          <Upload
            name="file"
            showUploadList={true}
            style={{ width: "435px !important" }}
            onRemove={(file) => {
              const files = state.files.slice(0);
              const index = state.files.findIndex((f) => f.name === file.name);
              files.splice(index, 1);
              setState((prevState) => ({
                ...prevState,
                files,
                isChanged: files.length > 0,
              }));
            }}
            beforeUpload={(file) => {
              const files = state.files.slice(0);
              files.push(file);
              setState((prevState) => ({
                ...prevState,
                files,
                isChanged: true,
              }));
              return false;
            }}
          >
            <Button style={{ float: "right" }}>
              <UploadOutlined /> {t("common:upload")}
            </Button>
          </Upload>
        )}
      </CmsForm>

      <Table
        dataSource={getValues().attachments}
        rowKey={(row, index) => row.id || (index as number)}
        bordered
        size={"small"}
        style={{ width: "435px" }}
        columns={[
          {
            title: t("properties:filename"),
            dataIndex: "filename",
            key: "filename",
            render: (text: any, record: any, index) => {
              if (IsImage(getValues().attachments?.[index].fileName)) {
                return (
                  <Popover
                    content={
                      <img
                        src={`${config.apiUrl}file/${getValues().attachments?.[index].fileId}`}
                        style={{ width: "300px" }}
                        alt="Preview"
                      />
                    }
                  >
                    <div style={{ width: "100%" }}>{getValues().attachments?.[index].fileName}</div>
                  </Popover>
                );
              } else {
                return (
                  <div style={{ width: "100%" }}>{getValues().attachments?.[index].fileName}</div>
                );
              }
            },
          },
          {
            dataIndex: "actions",
            align: "right",
            render: (text: any, record: any, index) => (
              <RowButtonSet
                onDelete={() => {
                  const attachments = getValues().attachments?.slice(0);
                  attachments?.splice(index, 1);

                  setValue("attachments", attachments);
                  setState((prevState) => ({
                    ...prevState,
                    isChanged: true,
                  }));
                }}
              />
            ),
            width: "60px",
          },
        ]}
        pagination={false}
        onRow={(_, index) => ({
          onClick: () => {
            const url = `${config.apiUrl}file/${getValues().attachments?.[index ?? -1].fileId}`;
            window.open(url, "_blank");
          },
        })}
      />
    </Modal>
  );
};
