import { Button, Form, Input, Layout, Modal, Select, Transfer } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { CmsModalForm, CmsSelect } from "../../common/FormComponents";

type DetailModalField = {
  name: string;
  type: "INPUT" | "SELECT" | "TRANSFER";
  dataset?: {
    id?: string;
    caption?: string;
  }[];
};

export const DetailsModal = <T extends {}>({
  title,
  show,
  data,
  fields,
  validate,
  onClose,
  onSave,
}: {
  title: string;
  show: boolean;
  data: T;
  fields: DetailModalField[];
  validate: (data: T) => any;
  onClose: () => void;
  onSave: (data: T) => void;
}) => {
  const { t } = useTranslation();
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  return (
    <Formik
      initialValues={data}
      enableReinitialize={true}
      validate={validate}
      onSubmit={(values) => onSave(values)}
    >
      {(formikProps) => {
        const { handleChange, handleSubmit, setFieldValue, values, errors } = formikProps;

        return (
          <Modal
            width={700}
            title={t(`entities:${title}`)}
            open={show}
            footer={[
              <Button id="btnCancel" key="cancel" onClick={onClose}>
                {t("common:cancel")}
              </Button>,
              <Button
                id="btnSave"
                key="save"
                type="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("common:save")}
              </Button>,
            ]}
            onCancel={onClose}
          >
            <Layout style={{ background: "#fff" }}>
              <div key={`${title}Details`}>
                <CmsModalForm>
                  {fields.map((field) => (
                    <>
                      {field.type === "INPUT" && (
                        <Form.Item
                          {...layout}
                          label={t(`properties:${field.name}`)}
                          validateStatus={!errors[field.name] ? "success" : "error"}
                          help={errors[field.name]}
                          required={true}
                        >
                          <Input
                            id={field.name}
                            placeholder={t(`properties:${field.name}`)}
                            maxLength={50}
                            value={values[field.name]}
                            onChange={handleChange}
                            onBlur={handleChange}
                          />
                        </Form.Item>
                      )}

                      {field.type === "SELECT" && (
                        <CmsSelect
                          {...layout}
                          id={field.name}
                          required={true}
                          label={t(`properties:${field.name}`)}
                          error={errors[field.name]}
                          onChange={(value) => setFieldValue(field.name, value)}
                          value={values[field.name]}
                        >
                          {field.dataset &&
                            field.dataset.map((data, index) => (
                              <Select.Option key={`${index}-${data.id}`} value={data.id}>
                                {data.caption}
                              </Select.Option>
                            ))}
                        </CmsSelect>
                      )}

                      {field.type === "TRANSFER" && (
                        <Form.Item
                          {...layout}
                          label={t("entities:currencies")}
                          validateStatus={!errors[field.name] ? "success" : "error"}
                          help={errors[field.name]}
                          required={true}
                        >
                          <Transfer
                            dataSource={
                              field.dataset &&
                              field.dataset.map((data) => ({
                                key: data.id,
                                title: data.caption,
                              }))
                            }
                            disabled={false}
                            targetKeys={values[field.name]}
                            onChange={(value) => {
                              setFieldValue("currencyIds", value);
                            }}
                            render={(item) => item.title as any}
                          />
                        </Form.Item>
                      )}
                    </>
                  ))}
                </CmsModalForm>
              </div>
            </Layout>
          </Modal>
        );
      }}
    </Formik>
  );
};
