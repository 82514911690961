import { Typography } from "antd";
import { DashboardOutlined } from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { CmsDashboard } from "./CmsDashboard";
const { Title } = Typography;

export const Home = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Title>
        <DashboardOutlined /> {t("common:dashboard")}
      </Title>
      <CmsDashboard />
    </React.Fragment>
  );
};
