import { useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";
import { SearchSetting } from "../../utilities/configs/DocumentStatisticsConfig";
import { CONST_SEARCH_SETTINGS } from "../../utilities/constants/query-contants";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";

export const postSearchSetting = (payload: SearchSetting): Promise<void> => {
  return api.post({ path: CONST_SEARCH_SETTINGS.singular, body: { ...payload } });
};

export const useSaveSearchSetting = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: SearchSetting) => postSearchSetting(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_SEARCH_SETTINGS.singular]);
      queryClient.invalidateQueries([CONST_SEARCH_SETTINGS.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: "Search setting",
        }),
      );
    },
  });
};
