import { Button, Layout, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { SecurityFeatureSelection } from "../../../models/data/SecurityFeatureSelection";
import { CmsButton } from "../../common/ButtonComponents";
import { useTranslation } from "react-i18next";
import { Point } from "react-lasso-select/lib/helpers";
import ReactCropper, { ReactCropperElement } from "react-cropper";
import { constructSecurityFeatureSelection } from "../../../utilities/helpers/security-features-helper";
import ReactLassoSelect from "react-lasso-select";
import { CropperModal } from "./FieldValues/CropperModal";
import { EditOutlined } from "@ant-design/icons";
import { PolyCropperModal } from "./FieldValues/PolyCropperModal";

const { Sider, Content } = Layout;

export const SecurityFeatureSelectionEditor = ({
  src,
  featureName,
  selection,
  orientation,
  onClose,
  onSave,
}: {
  src: string;
  featureName: string;
  selection: SecurityFeatureSelection;
  orientation: number;
  onClose?: () => void;
  onSave: (selection: SecurityFeatureSelection) => void;
}) => {
  const { t } = useTranslation();
  const [copperModal, setCopperModal] = useState<{ open: boolean; selection?: Cropper.Data }>({
    open: false,
    selection: undefined,
  });
  const [polyModal, setPolyModal] = useState<{ open: boolean; selection?: Point[] }>({
    open: false,
    selection: undefined,
  });

  const [polyState, setPolyState] = useState<Point[] | undefined>(undefined);
  const [rectState, setRectState] = useState<Cropper.Data | undefined>(undefined);
  const cropperRef = useRef<ReactCropperElement>(null);

  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    natWidth: number;
    natHeight: number;
  }>({ width: 0, height: 0, natWidth: 0, natHeight: 0 });

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      setImageSize({
        width: image.width,
        height: image.height,
        natHeight: image.naturalHeight,
        natWidth: image.naturalWidth,
      });
    };
  }, []);

  useEffect(() => {
    if (!!selection) {
      construct(selection, selection.shapeType === "RECTANGLE" ? "rect" : "poly", false);
    }
  }, []);

  const destructRect = (fieldValue: SecurityFeatureSelection) => {
    return {
      height: (fieldValue?.height ?? 1) / imageSize.height,
      width: (fieldValue?.width ?? 1) / imageSize.width,
      x: (fieldValue?.x ?? 1) / imageSize.width,
      y: (fieldValue?.y ?? 1) / imageSize.height,
    };
  };

  const destructPoly = (points: Point[]) => {
    return {
      points: points?.map((point) => ({
        x: point.x / imageSize.width,
        y: point.y / imageSize.height,
      })),
    };
  };

  const construct = (
    secFeatureSelection: SecurityFeatureSelection,
    type: "rect" | "poly",
    modal: boolean = false,
  ) => {
    const image = new Image();
    image.src = src;
    image.crossOrigin = "anonymous";
    image.onload = () => {
      if (type === "rect") {
        const data = {
          height: image.height * (secFeatureSelection?.height ?? 1),
          width: image.width * (secFeatureSelection?.width ?? 1),
          x: image.width * (secFeatureSelection?.x ?? 1),
          y: image.height * (secFeatureSelection?.y ?? 1),
          rotate: orientation,
          scaleX: 1,
          scaleY: 1,
        } as Cropper.Data;

        if (modal) {
          setCopperModal({
            open: true,
            selection: data,
          });
        }

        setRectState(data);
      }

      if (type === "poly") {
        const poly = secFeatureSelection.points?.map((point) => ({
          x: image.width * point.x,
          y: image.height * point.y,
        }));

        if (modal) {
          setPolyModal({
            open: true,
            selection: secFeatureSelection.points?.map((point) => ({
              x: image.width * point.x,
              y: image.height * point.y,
            })),
          });
        }

        setPolyState(poly);
      }
    };
  };

  const saveAndReset = () => {
    onSave(constructSecurityFeatureSelection(selection));
    setCopperModal({ open: false, selection: undefined });
    setPolyModal({ open: false, selection: undefined });
    setPolyState(undefined);
    setRectState(undefined);
  };

  return (
    <>
      <Modal
        style={{ height: "750px", maxHeight: "80%" }}
        title={featureName}
        width={1000}
        open={true}
        closable={true}
        onCancel={() => {
          onClose && onClose();
          setPolyState(undefined);
          setRectState(undefined);
        }}
        footer={[
          <CmsButton
            buttonType="default"
            key="cancel"
            onClick={() => {
              onClose && onClose();
              setPolyState(undefined);
              setRectState(undefined);
            }}
          >
            Ok
          </CmsButton>,
        ]}
      >
        <Layout
          style={{
            borderRadius: 8,
            overflow: "hidden",
            width: "100%",
            maxWidth: "100%",
            minHeight: "600px",
          }}
        >
          <Sider
            width="50%"
            style={{
              position: "relative",
              textAlign: "center",
              lineHeight: "120px",
              color: "#fff",
              backgroundColor: "#f5f5f5",
              justifyContent: "center",
              maxHeight: "600px",
              maxWidth: "470px",
              overflow: "hidden",
            }}
          >
            <div className={"previewContainer"}>
              {src ? (
                <>
                  {polyState !== undefined && (
                    <div style={{ maxWidth: "100%", maxHeight: "600px" }}>
                      <ReactLassoSelect
                        src={src}
                        value={polyState}
                        imageStyle={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </div>
                  )}
                  {rectState !== undefined && (
                    <ReactCropper
                      ref={cropperRef}
                      id="selection-area"
                      preview={".preview"}
                      src={src}
                      dragMode="none"
                      background={false}
                      autoCrop={!!rectState}
                      viewMode={2}
                      style={{ display: "block", maxWidth: "100%", maxHeight: "600px" }}
                      data={rectState}
                      scalable={false}
                      zoomable={false}
                      draggable={false}
                      movable={false}
                      disabled
                    />
                  )}
                  {rectState === undefined && polyState === undefined && (
                    <img
                      src={src}
                      style={{ maxWidth: "100%", maxHeight: "600px" }}
                      alt="document-image"
                    />
                  )}
                </>
              ) : (
                <img
                  src="/assets/images/keesing-logo.jpg"
                  style={{ minWidth: "100%" }}
                  alt="Keesing logo"
                />
              )}
            </div>
          </Sider>

          <Layout>
            <Content
              style={{
                textAlign: "center",
                minHeight: 50,
                lineHeight: "50px",
                color: "#fff",
              }}
            >
              <Button
                type="primary"
                icon={<EditOutlined />}
                style={{ margin: "0px 2px" }}
                size="small"
                onClick={() => {
                  construct(selection, "rect");
                  setCopperModal({
                    open: true,
                    selection: rectState,
                  });
                }}
              >
                Rectangle
              </Button>
              <Button
                title="Currently not in use"
                type="primary"
                icon={<EditOutlined />}
                style={{ margin: "0px 2px" }}
                size="small"
                onClick={() => {
                  construct(selection, "poly");
                  setPolyModal({ open: true, selection: selection.points });
                }}
                disabled
              >
                Polygon
              </Button>
            </Content>
            <Content
              style={{
                textAlign: "center",
                color: "#fff",
                height: "100%",
                paddingInline: 48,
                lineHeight: "64px",
              }}
            >
              <h3 style={{ color: "black" }}>{t("common:preview")}</h3>
              <div style={{ position: "relative", height: "200px", width: "350px" }}>
                {!!rectState && (
                  <div className="previewContainer">
                    <div
                      className="preview"
                      style={{
                        maxHeight: "200px",
                        maxWidth: "350px",
                        height: rectState?.height,
                        width: rectState?.width,
                      }}
                    />
                  </div>
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Modal>
      {copperModal.open && (
        <CropperModal
          file={src}
          open={copperModal.open}
          title={featureName}
          onClose={() => setCopperModal({ open: false, selection: undefined })}
          onSave={(value, another) => {
            const newValue = destructRect({
              width: value.width,
              height: value.height,
              x: value.x,
              y: value.y,
            } as SecurityFeatureSelection);

            selection.height = newValue.height;
            selection.width = newValue.width;
            selection.x = newValue.x;
            selection.y = newValue.y;
            selection.shapeType = "RECTANGLE";

            saveAndReset();

            if (another) {
              setCopperModal({ open: true, selection: undefined });
            }
          }}
          preSelection={copperModal.selection}
        />
      )}
      {polyModal.open && (
        <PolyCropperModal
          fileId={src}
          open={polyModal.open}
          onClose={() => setPolyModal({ open: false, selection: undefined })}
          onSave={(value) => {
            const newValue = destructPoly(value);

            selection.points = newValue.points;
            selection.shapeType = "POLYGON";

            saveAndReset();
          }}
          preSelection={polyModal.selection}
        />
      )}
    </>
  );
};
