export const DOCUMENTTEMPLATE_NAMES = {
  DOUBLE_SIDED: "Double-sided",
  MULTI_SIDED: "Multi-sided",
  COUNTERFEIT_BANKNOTE: "Counterfeit banknote",
  BANKNOTE: "Genuine banknote",
};

export const ERROR_CODES = {
  BAD_REQUEST: "400",
  CONFLICT: "409",
  SERVER_ERROR: "500",
};
