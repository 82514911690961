import { TFunction } from "i18next";
import { DocumentSeries } from "../../models/data/Document";
import { validateRequired } from "../Validators";
import { DOCUMENTTEMPLATE_NAMES } from "../Constants";
import * as _ from "lodash";
import { checkDocumentCode } from "../../queries/code-generator/detail";

export const ValidateDocumentAsync = async (
  document: DocumentSeries,
  t: TFunction,
  currentCode?: string,
) => {
  const errors: any = {};

  const codeResult = validateRequired(document.code, t("properties:code"));
  if (codeResult) {
    errors.code = codeResult;
  } else {
    if (document.code && currentCode !== document.code) {
      const code = await checkDocumentCode(document.code);
      if (!code.available) {
        errors.code = t("validations:inputCodeInUse", {
          value: document.code,
        });
      }
    }
  }

  const documentTypeResult = validateRequired(document.documentTypeId, t("properties:type"));
  if (documentTypeResult) {
    errors.documentTypeId = documentTypeResult;
  }

  const materialTypeResult = validateRequired(
    document.materialTypeId,
    t("properties:materialType"),
  );
  if (materialTypeResult) {
    errors.materialTypeId = materialTypeResult;
  }

  return errors;
};

export const getDocumentCheckerUrl = (documentTemplateName?: string) => {
  switch (documentTemplateName) {
    case DOCUMENTTEMPLATE_NAMES.DOUBLE_SIDED:
    case DOCUMENTTEMPLATE_NAMES.MULTI_SIDED:
      return "document";
    case DOCUMENTTEMPLATE_NAMES.COUNTERFEIT_BANKNOTE:
      return "counterfeit";
    case DOCUMENTTEMPLATE_NAMES.BANKNOTE:
      return "banknote";
    default:
      return "";
  }
};

export const updateFormStatusWithSection = (
  currentStatus: any,
  section: string,
  valid: boolean,
): any => {
  const newStatus = _.cloneDeep(currentStatus);
  if (!valid && !newStatus.invalidSections.has(section)) {
    newStatus.invalidSections.add(section);
  } else if (valid && newStatus.invalidSections.has(section)) {
    newStatus.invalidSections.delete(section);
  }
  return newStatus;
};
