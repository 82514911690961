import { useQuery, useQueryClient } from "@tanstack/react-query";
import { MaterialType } from "../../models/reference_lists/MaterialType";
import api from "../../services/api";
import { useMutationBase } from "../useMutationBase";
import { useNotification } from "../../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { CONST_MATERIAL_TYPES } from "../../utilities/constants/query-contants";

export const getMaterialType = (id?: string): Promise<MaterialType> => {
  return api.get({
    path: `${CONST_MATERIAL_TYPES.plural}/${id}`,
  });
};

export const useQueryMaterialType = (id?: string) => {
  return useQuery<MaterialType>(
    [CONST_MATERIAL_TYPES.singular, { id }],
    () => getMaterialType(id),
    {
      enabled: !!id,
    },
  );
};

export const postMaterialType = (payload: MaterialType): Promise<void> => {
  return api.post({ path: CONST_MATERIAL_TYPES.plural, body: { ...payload } });
};

export const useSaveMaterialType = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: MaterialType) => postMaterialType(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_MATERIAL_TYPES.singular]);
      queryClient.invalidateQueries([CONST_MATERIAL_TYPES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:materialType").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteMaterialType = (id: string) => {
  return api.delete({ path: `${CONST_MATERIAL_TYPES.plural}/${id}` });
};

export const useDeleteMaterialType = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteMaterialType(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_MATERIAL_TYPES.singular]);
      queryClient.invalidateQueries([CONST_MATERIAL_TYPES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:materialType").toLocaleLowerCase(),
        }),
      );
    },
  });
};
