import { Select } from "antd";
import { Formik } from "formik";
import React, { Key } from "react";
import { useTranslation } from "react-i18next";
import { CmsDownloadButton, CmsUploadButton } from "../common/ButtonComponents";
import { CmsForm, CmsSelect } from "../common/FormComponents";
import { CmsPageHeader, CmsPageLoader } from "../common/PageComponents";
import { useQueryLanguages } from "../../queries/languages/lists";
import { useExportTranslation, useImportTranslation } from "../../queries/translations/detail";
import { Languages } from "../../queries/languages/types";
import { validateTranslation } from "../../utilities/helpers/translation-helper";

export const Translations = () => {
  const { t } = useTranslation();
  const [state, setState] = React.useState<Languages>({
    source: undefined,
    target: undefined,
  });

  const { data: languages, isLoading: isLoadingLanguages } = useQueryLanguages();
  const { mutateAsync: exportTranslation, isLoading: isLoadingExportTranslation } =
    useExportTranslation();
  const { mutateAsync: importTranslation, isLoading: isLoadingImportTranslation } =
    useImportTranslation();

  return (
    <React.Fragment>
      <CmsPageLoader
        loading={isLoadingLanguages || isLoadingExportTranslation || isLoadingImportTranslation}
        subTitle={t("common:loadingData")}
      >
        {languages && (
          <Formik
            initialValues={state}
            enableReinitialize={true}
            validate={(languages) => validateTranslation(languages, t)}
            onSubmit={async (languages) => {
              setState(languages);
              await exportTranslation(languages);
            }}
          >
            {(formikProps) => {
              const { handleSubmit, setFieldValue, errors, isValid } = formikProps;

              return (
                <React.Fragment>
                  <CmsPageHeader
                    title={t("common:translations")}
                    extra={[
                      <CmsUploadButton
                        key="import"
                        accept=".csv"
                        customRequest={async (options) => await importTranslation(options.file)}
                      />,
                      <CmsDownloadButton
                        key="export"
                        disabled={!isValid}
                        onClick={() => handleSubmit()}
                      />,
                    ]}
                  />
                  <CmsForm>
                    <CmsSelect
                      label={t("properties:sourceLanguage")}
                      error={errors.source}
                      required={true}
                      placeholder={t("entities:language")}
                      onChange={(value) => setFieldValue("source", value)}
                      children={languages.map((language, index) => (
                        <Select.Option key={`field_${index}`} value={language.isoCode as Key}>
                          {language.name}
                        </Select.Option>
                      ))}
                    />

                    <CmsSelect
                      label={t("properties:targetLanguage")}
                      error={errors.target}
                      required={true}
                      placeholder={t("entities:language")}
                      onChange={(value) => setFieldValue("target", value)}
                      children={languages.map((language, index) => (
                        <Select.Option key={`field_${index}`} value={language.isoCode as Key}>
                          {language.name}
                        </Select.Option>
                      ))}
                    />
                  </CmsForm>
                </React.Fragment>
              );
            }}
          </Formik>
        )}
      </CmsPageLoader>
    </React.Fragment>
  );
};
