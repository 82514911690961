import { DocumentCode } from "../../models/data/DocumentCode";
import api from "../../services/api";
import {
  CONST_CODE_GENERATOR,
  CONST_COUNTRIES,
  CONST_REGIONS,
} from "../../utilities/constants/query-contants";

export const checkDocumentCode = (code?: string): Promise<DocumentCode> => {
  return api.get({
    path: `${CONST_CODE_GENERATOR}/${code}`,
  });
};

export const generateCodeByCountry = (payload: CountryCodeGenerationPayload): Promise<string> => {
  return api.post({
    path: `${CONST_CODE_GENERATOR}/${CONST_COUNTRIES.singular}`,
    body: payload,
  });
};

export const generateCodeByRegion = (payload: RegionCodeGenerationPayload): Promise<string> => {
  return api.post({
    path: `${CONST_CODE_GENERATOR}/${CONST_REGIONS.singular}`,
    body: payload,
  });
};

type RegionCodeGenerationPayload = CodeGenerationPayload & {
  regionId: string;
};

type CountryCodeGenerationPayload = CodeGenerationPayload & {
  countryId: string;
};

type CodeGenerationPayload = {
  documentTypeId: string;
};
