import { useTranslation } from "react-i18next";
import { Attribute } from "../../../../models/templates/Attribute";
import { capitalizeFirstLetter } from "../../../../utilities/StringHelper";
import { CmsSelect } from "../../../common/FormComponents";
import { getLocalizedValue } from "../../../../utilities/MultilingualHelper";
import { useQueryCountries } from "../../../../queries/countries/lists";
import { Select } from "antd";
import { Key } from "react";
import { sanatizeGuid } from "../../../../utilities/helpers/index-filtering-helper";

export const CountryListFilter = ({
  attr,
  defaultValue,
  updateDefaultValue,
}: {
  attr?: Attribute;
  defaultValue?: any;
  updateDefaultValue: (id: string, value: any) => void;
}) => {
  const { t } = useTranslation();
  const { data: countries, isLoading: isLoadingCountries } = useQueryCountries();

  return (
    <CmsSelect
      id="documentCountry"
      label={capitalizeFirstLetter(getLocalizedValue(attr?.caption) || attr?.name || "")}
      placeholder={t("entities:country")}
      value={defaultValue !== undefined ? defaultValue : undefined}
      onChange={(e) => attr?.id && updateDefaultValue(attr?.id, e)}
    >
      {!isLoadingCountries &&
        countries &&
        countries.map((country) => (
          <Select.Option key={country.isoCode2} value={sanatizeGuid(country.id as string) as Key}>
            {getLocalizedValue(country.name)}
          </Select.Option>
        ))}
    </CmsSelect>
  );
};
