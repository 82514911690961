import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { ErrorResponse } from "../models/ErrorResponse";
import { ERROR_CODES } from "../utilities/Constants";
import { errorFormatter } from "../utilities/ObjectHelper";
import { useNotification } from "../hooks/useNotification";
import { useTranslation } from "react-i18next";
import { useCmsContext } from "../context/app/CmsContext";

export const useMutationBase = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
) => {
  const { notifyError } = useNotification();
  const { t } = useTranslation();
  const context = useCmsContext();

  return useMutation({
    ...options,
    onError: (err: TError) => {
      const error = err as ErrorResponse;
      switch (error.errorCode) {
        case "4000":
          if (error.errorDetails) {
            notifyError(
              error.errorCode ?? ERROR_CODES.BAD_REQUEST,
              <div>
                {error.errorMessage}
                <ul>
                  {errorFormatter(error.errorDetails).map((detail) => (
                    <li>{`- ${detail}`}</li>
                  ))}
                </ul>
              </div>,
            );
          } else {
            notifyError(
              error.errorCode ?? ERROR_CODES.BAD_REQUEST,
              <div>{[error.errorMessage]}</div>,
            );
          }
          break;
        case "4090":
          notifyError(
            error.errorCode ?? ERROR_CODES.BAD_REQUEST,
            <div>{context?.duplicateErrorMessage ?? error.errorMessage}</div>,
          );
          break;
        default:
          notifyError(ERROR_CODES.SERVER_ERROR, t("errors:networkError"));
      }

      return "onError" as TContext;
    },
  });
};
