import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { App } from "./App";
import Config from "./Config";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import "./styles/spinner.css";
import "./index.scss";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { CmsPageLoader } from "./components/common/PageComponents";

Config.loadConfiguration().then(() => {
  ReactDOM.render(
    <ReactKeycloakProvider
      authClient={
        new Keycloak({
          url: Config.idsUrl,
          realm: Config.realm,
          clientId: Config.appId,
        })
      }
      LoadingComponent={
        <CmsPageLoader
          loading={true}
          key={"keycloak-pageloader"}
          title={"Authenticating."}
          subTitle={"On moment please"}
        />
      }
      initOptions={{
        response_type: "id_token token",
        scope: "openid profile email",
        onload: "login-required",
      }}
      onTokens={(tokens) => tokens.token && sessionStorage.setItem("token", tokens.token)}
    >
      <App />
    </ReactKeycloakProvider>,
    document.getElementById("root"),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
