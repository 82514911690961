export const getOrientationAdjustment = (exifOrientation: number) => {
  switch (exifOrientation) {
    default:
    case 1:
    case 2:
      return 0;
    case 3:
    case 4:
      return 180;
    case 5:
    case 6:
      return 90;
    case 7:
    case 8:
      return -90;
  }
}