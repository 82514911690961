import { notification } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const useNotification = () => {
  const { t } = useTranslation();

  const notifySuccess = (description: ReactNode, message?: string) => {
    notification.success({
      message: message ?? t("common:success"),
      description,
      duration: 5,
    });
  };

  const notifyError = (code: string, description: ReactNode) => {
    notification.error({
      message: `${t("common:error")} (${code})`,
      description,
      duration: 5,
    });
  };

  return {
    notifySuccess,
    notifyError,
  };
};
