import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SecurityFeature } from "../../models/templates/SecurityFeature";
import api from "../../services/api";
import { useTranslation } from "react-i18next";
import { useNotification } from "../../hooks/useNotification";
import { useMutationBase } from "../useMutationBase";
import { CONST_SECURITY_FEATURES } from "../../utilities/constants/query-contants";

export const getSecurityFeature = (id?: string): Promise<SecurityFeature> => {
  return api.get({
    path: `${CONST_SECURITY_FEATURES.plural}/${id}`,
  });
};

export const useQuerySecurityFeature = (id?: string) => {
  return useQuery<SecurityFeature>(
    [CONST_SECURITY_FEATURES.singular, { id }],
    () => getSecurityFeature(id),
    {
      enabled: !!id,
    },
  );
};

export const postSecurityFeature = (payload: SecurityFeature): Promise<void> => {
  return api.post({
    path: CONST_SECURITY_FEATURES.plural,
    body: { ...payload },
  });
};

export const useSaveSecurityFeature = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (payload: SecurityFeature) => postSecurityFeature(payload),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_SECURITY_FEATURES.singular]);
      queryClient.invalidateQueries([CONST_SECURITY_FEATURES.plural]);

      notifySuccess(
        t("common:successSave", {
          entity: t("entities:securityFeature").toLocaleLowerCase(),
        }),
      );
    },
  });
};

export const deleteSecurityFeature = (id: string) => {
  return api.delete({ path: `${CONST_SECURITY_FEATURES.plural}/${id}` });
};

export const useDeleteSecurityFeature = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { notifySuccess } = useNotification();

  return useMutationBase({
    mutationFn: (id: string) => deleteSecurityFeature(id),
    onSuccess: () => {
      queryClient.invalidateQueries([CONST_SECURITY_FEATURES.singular]);
      queryClient.invalidateQueries([CONST_SECURITY_FEATURES.plural]);

      notifySuccess(
        t("common:successDelete", {
          entity: t("common:securityFeature").toLocaleLowerCase(),
        }),
      );
    },
  });
};
