import { TFunction } from "i18next";
import { Languages } from "../../queries/languages/types";
import { validateDifferent, validateRequired } from "../Validators";

export const validateTranslation = (languages: Languages, t: TFunction) => {
  const errors: any = {};

  const sourceResult = validateRequired(languages.source, t("properties:sourceLanguage"));
  if (sourceResult != null) {
    errors.source = sourceResult;
  }

  const targetResult = validateRequired(languages.target, t("properties:targetLanguage"));
  if (targetResult != null) {
    errors.target = targetResult;
  }

  if (sourceResult == null && targetResult == null) {
    const differentResult = validateDifferent(
      languages.source,
      languages.target,
      t("properties:sourceLanguage").toLowerCase(),
      t("properties:targetLanguage").toLowerCase(),
    );
    if (differentResult != null) {
      errors.target = differentResult;
    }
  }

  return errors;
};
