import { useQuery } from "@tanstack/react-query";
import { Organization } from "../../models/reference_lists/Organization";
import api from "../../services/api";

export const getOrganizations = (): Promise<Organization[]> => {
  return api.get({
    path: `organizations`,
  });
};

export const useQueryOrganizations = () => {
  return useQuery<Organization[]>(["organizations"], () => getOrganizations());
};
