import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { CmsSelect } from "../common/FormComponents";
import { ReactNode } from "react";
import { Select } from "antd";

export default function ControlledSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  data,
  label,
  isLoading,
  canUpdate,
  showSearch = false,
  rules,
  customOnChange,
}: {
  control: Control<TFieldValues, any>;
  name: TName;
  data: { name: string; value: ReactNode }[];
  label: string;
  isLoading: boolean;
  canUpdate: boolean;
  showSearch?: boolean;
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  customOnChange?: (e) => void;
}) {
  return (
    <Controller
      control={control}
      name={name}
      disabled={isLoading}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref, name, disabled },
        fieldState: { error },
      }) => (
        <CmsSelect
          showSearch={showSearch}
          label={label}
          onChange={customOnChange ?? onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          disabled={disabled}
          error={error}
          ref={ref}
          readOnly={!canUpdate}
        >
          {data.map((item) => (
            <Select.Option value={item.value}>{item.name}</Select.Option>
          ))}
        </CmsSelect>
      )}
    />
  );
}
